import React from 'react';

interface LogoProps {
  className?: string;
  filter?: string;
  onClick?: () => void;
}

const Logo = ({ className = "h-24 w-auto md:h-24 h-[4.5rem]", filter = "", onClick }: LogoProps) => {
  return (
    <img 
      src="/lovable-uploads/de530089-817f-45cb-8c4b-5d336c0db5a3.png" 
      alt="Draft'd Logo" 
      className={`${className} transition-all duration-300`}
      style={{ filter }}
      onClick={onClick}
      role={onClick ? "button" : undefined}
      tabIndex={onClick ? 0 : undefined}
    />
  );
};

export default Logo;