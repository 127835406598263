import { ReactNode } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { DashboardNav } from "./DashboardNav"
import Logo from "@/components/Logo"
import { Button } from "../ui/button"
import { Menu, Moon, Sun } from "lucide-react"
import { useState, useEffect } from "react"

interface PlayerDashboardLayoutProps {
  children: ReactNode
}

export function PlayerDashboardLayout({ children }: PlayerDashboardLayoutProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [theme, setTheme] = useState<'light' | 'dark'>('dark')

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
      setTheme(savedTheme as 'light' | 'dark')
      document.documentElement.classList.toggle('dark', savedTheme === 'dark')
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      setTheme(prefersDark ? 'dark' : 'light')
      document.documentElement.classList.toggle('dark', prefersDark)
    }
  }, [])

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    setTheme(newTheme)
    localStorage.setItem('theme', newTheme)
    document.documentElement.classList.toggle('dark')
  }

  return (
    <div className="min-h-screen bg-background transition-colors duration-300">
      <div className="flex">
        {/* Sidebar */}
        <div 
          className={`
            ${sidebarOpen ? 'w-56' : 'w-0'} 
            transition-all duration-300 
            futuristic-sidebar
            fixed h-full z-50
            lg:relative
          `}
        >
          <div className="h-14 border-b border-border/50 flex items-center px-4">
            <Logo 
              className="h-8 w-auto cursor-pointer transition-opacity duration-300" 
              onClick={() => navigate("/")} 
              filter={theme === 'dark' ? 'brightness(1)' : 'brightness(0.2)'}
            />
          </div>
          <DashboardNav />
        </div>

        {/* Main content */}
        <div className="flex-1 min-h-screen">
          <header className="h-14 futuristic-header px-4 flex items-center justify-between w-full z-40">
            <div className="flex items-center gap-3">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className="lg:hidden"
              >
                <Menu className="h-4 w-4" />
              </Button>
              <h1 className="text-base font-semibold">
                {location.pathname === "/dashboard" ? "Dashboard" : 
                 location.pathname === "/my-profile" ? "My Profile" :
                 location.pathname === "/highlights" ? "Manage Highlights" :
                 location.pathname === "/offers" ? "Offers & Transfers" :
                 location.pathname === "/stats" ? "Stats & Performance" :
                 location.pathname === "/notifications" ? "Notifications" :
                 location.pathname === "/messages" ? "Messages" :
                 location.pathname === "/settings" ? "Settings" :
                 location.pathname === "/support" ? "Help & Support" : ""}
              </h1>
            </div>
            <div className="flex items-center gap-3">
              <Button
                variant="outline"
                size="icon"
                onClick={toggleTheme}
                className="rounded-full hover:bg-accent/10 transition-colors duration-300"
              >
                {theme === 'dark' ? (
                  <Sun className="h-4 w-4 text-accent" />
                ) : (
                  <Moon className="h-4 w-4 text-accent" />
                )}
              </Button>
            </div>
          </header>
          <main className="p-4">
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}