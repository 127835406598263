import { useState, useEffect } from "react"
import { HighlightsManagement } from "@/components/profile/sections/HighlightsManagement"
import { Card } from "@/components/ui/card"
import { supabase } from "@/lib/supabase"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

interface Highlight {
  id: string
  title: string
  description: string
  video_url: string
  is_active: boolean
}

export default function Highlights() {
  const [featuredHighlights, setFeaturedHighlights] = useState<Highlight[]>([])
  const [libraryHighlights, setLibraryHighlights] = useState<Highlight[]>([])

  useEffect(() => {
    const fetchHighlights = async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) return

      // Fetch featured highlights
      const { data: featured, error: featuredError } = await supabase
        .from('highlights')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_active', true)
        .order('position')

      if (featuredError) {
        console.error('Error fetching featured highlights:', featuredError)
      } else {
        setFeaturedHighlights(featured || [])
      }

      // Fetch library highlights
      const { data: library, error: libraryError } = await supabase
        .from('highlights')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_active', false)
        .order('created_at', { ascending: false })

      if (libraryError) {
        console.error('Error fetching library highlights:', libraryError)
      } else {
        setLibraryHighlights(library || [])
      }
    }

    fetchHighlights()
  }, [])

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-2xl font-bold mb-6">Manage Highlights</h1>
      
      <Tabs defaultValue="featured" className="space-y-6">
        <TabsList>
          <TabsTrigger value="featured">Featured Highlights</TabsTrigger>
          <TabsTrigger value="library">Highlight Library</TabsTrigger>
          <TabsTrigger value="upload">Upload New</TabsTrigger>
        </TabsList>

        <TabsContent value="featured">
          <Card className="p-6">
            <h2 className="text-lg font-semibold mb-4">Featured Highlights</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {featuredHighlights.map((highlight) => (
                <div key={highlight.id} className="space-y-2">
                  <div className="text-sm font-medium">{highlight.title}</div>
                  <div className="text-sm text-muted-foreground">{highlight.description}</div>
                  <div className="aspect-video bg-muted rounded-lg overflow-hidden">
                    {highlight.video_url ? (
                      <video 
                        src={highlight.video_url} 
                        controls 
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <p className="text-muted-foreground">No highlight video</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {featuredHighlights.length === 0 && (
                <div className="col-span-full text-center py-8 text-muted-foreground">
                  No featured highlights yet
                </div>
              )}
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="library">
          <Card className="p-6">
            <h2 className="text-lg font-semibold mb-4">Highlight Library</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {libraryHighlights.map((highlight) => (
                <div key={highlight.id} className="space-y-2">
                  <div className="text-sm font-medium">{highlight.title}</div>
                  <div className="text-sm text-muted-foreground">{highlight.description}</div>
                  <div className="aspect-video bg-muted rounded-lg overflow-hidden">
                    {highlight.video_url ? (
                      <video 
                        src={highlight.video_url} 
                        controls 
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <p className="text-muted-foreground">No highlight video</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {libraryHighlights.length === 0 && (
                <div className="col-span-full text-center py-8 text-muted-foreground">
                  No highlights in library
                </div>
              )}
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="upload">
          <HighlightsManagement />
        </TabsContent>
      </Tabs>
    </div>
  )
}