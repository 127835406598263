import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useState } from "react"

interface FileUploadProps {
  onUpload: (files: FileList) => void
}

export function FileUpload({ onUpload }: FileUploadProps) {
  const [fileName, setFileName] = useState<string>("")

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0) {
      setFileName(files[0].name)
      onUpload(files)
    }
  }

  return (
    <div className="space-y-2">
      <Input
        type="file"
        onChange={handleFileChange}
        className="hidden"
        id="file-upload"
        multiple
      />
      <label htmlFor="file-upload">
        <Button variant="outline" className="w-full" asChild>
          <span>
            {fileName ? `Selected: ${fileName}` : "Choose files"}
          </span>
        </Button>
      </label>
    </div>
  )
}