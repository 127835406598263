import { CheckCircle2 } from "lucide-react"

const scoutFeatures = [
  {
    title: "Advanced Filters",
    points: [
      "Search by position, height, weight, dominant foot, football level, club tier, playing style, age, nationality, contract status, to find exactly the talent you're looking for",
    ],
  },
  {
    title: "Player Profiles",
    points: [
      "Access detailed stats and achievements",
      "View unlimited video libraries",
      "Focus on key highlights for quick evaluation",
    ],
  },
  {
    title: "Streamlined Communication",
    points: [
      "Contact players directly through the platform",
      "Manage conversations efficiently",
      "Keep track of potential recruits",
    ],
  },
]

const scoutBenefits = [
  {
    title: "Save Time",
    description: "No more sorting through irrelevant players—find exactly what you're looking for.",
  },
  {
    title: "Global Reach",
    description: "Access a vast pool of athletes from all levels and countries.",
  },
  {
    title: "Simplify Recruitment",
    description: "From search to communication, make finding the right player effortless.",
  },
]

export const ScoutFeatures = () => {
  return (
    <section className="py-24 px-6 bg-background">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-semibold mb-8 text-center">For Scouts & Clubs: Find Your Perfect Fit</h2>
        
        <div className="mb-16">
          <h3 className="text-2xl font-semibold mb-6">Effortless Talent Discovery</h3>
          <div className="grid md:grid-cols-2 gap-8">
            {scoutFeatures.map((feature, index) => (
              <div key={index} className="dashboard-card">
                <h4 className="text-xl font-semibold mb-4">{feature.title}</h4>
                <ul className="space-y-3">
                  {feature.points.map((point, pIndex) => (
                    <li key={pIndex} className="flex gap-2">
                      <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-1" />
                      <span className="text-muted-foreground">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center max-w-3xl mx-auto">
          <h3 className="text-2xl font-semibold mb-6">Why Scouts and Clubs Choose Draft'd</h3>
          <div className="grid md:grid-cols-3 gap-8">
            {scoutBenefits.map((benefit, index) => (
              <div key={index} className="dashboard-card">
                <h4 className="text-xl font-semibold mb-3">{benefit.title}</h4>
                <p className="text-muted-foreground">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}