import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { Edit } from "lucide-react"

interface ProfileHeaderProps {
  playerData: any
  onEdit: () => void
}

export function ProfileHeader({ playerData, onEdit }: ProfileHeaderProps) {
  return (
    <Card className="dashboard-card overflow-hidden">
      <div className="relative h-32 bg-gradient-to-r from-primary/80 to-primary">
        <div className="absolute -bottom-16 left-8">
          <div className="h-32 w-32 rounded-xl border-4 border-background bg-background shadow-lg overflow-hidden">
            {playerData.profile_picture_url ? (
              <img
                src={playerData.profile_picture_url}
                alt={playerData.full_name}
                className="h-full w-full object-cover"
              />
            ) : (
              <div className="h-full w-full bg-muted flex items-center justify-center">
                <span className="text-muted-foreground text-4xl font-light">
                  {playerData.full_name?.[0]?.toUpperCase()}
                </span>
              </div>
            )}
          </div>
        </div>
        <Button
          onClick={onEdit}
          className="absolute top-4 right-4 glass-button"
        >
          <Edit className="h-4 w-4 mr-2" />
          Edit Profile
        </Button>
      </div>
      <div className="pt-20 pb-6 px-8">
        <h1 className="text-2xl font-semibold mb-1 text-foreground">{playerData.full_name}</h1>
        <p className="text-muted-foreground">
          {playerData.position} • {playerData.current_club || "Free Agent"}
        </p>
      </div>
    </Card>
  )
}