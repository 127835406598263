import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Edit } from "lucide-react"
import { useToast } from "@/components/ui/use-toast"

interface BasicInfoSectionProps {
  playerData: {
    fullName: string;
    dateOfBirth: string;
    gender: string;
    city: string;
    country: string;
    email?: string;
    phone?: string;
  };
}

export function BasicInfoSection({ playerData }: BasicInfoSectionProps) {
  const { toast } = useToast()
  
  const handleEditClick = () => {
    toast({
      title: "Edit mode",
      description: "This feature is coming soon!"
    })
  }

  return (
    <Card className="dashboard-card">
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-lg font-semibold">Basic Information</h2>
        <Button variant="ghost" size="sm" onClick={handleEditClick}>
          <Edit className="h-4 w-4" />
        </Button>
      </div>
      <div className="space-y-4">
        <div>
          <p className="text-sm text-muted-foreground">Full Name</p>
          <p className="font-medium">{playerData?.fullName}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Date of Birth</p>
          <p className="font-medium">{playerData?.dateOfBirth}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Gender</p>
          <p className="font-medium">{playerData?.gender}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Location</p>
          <p className="font-medium">{playerData?.city}, {playerData?.country}</p>
        </div>
        {playerData?.email && (
          <div>
            <p className="text-sm text-muted-foreground">Email</p>
            <p className="font-medium">{playerData.email}</p>
          </div>
        )}
        {playerData?.phone && (
          <div>
            <p className="text-sm text-muted-foreground">Phone</p>
            <p className="font-medium">{playerData.phone}</p>
          </div>
        )}
      </div>
    </Card>
  )
}