import { useState } from "react"
import { 
  Bell, 
  Heart, 
  UserPlus, 
  MessageCircle, 
  Eye, 
  Trophy,
  Star,
  Calendar,
  Clipboard,
  Share2,
  ThumbsUp,
  Video,
  Mail,
  AlertCircle
} from "lucide-react"

interface Notification {
  id: string
  type: string
  content: string
  created_at: string
  is_read: boolean
  priority?: "high" | "medium" | "low"
}

const mockNotifications: Notification[] = [
  {
    id: "1",
    type: "scout_interest",
    content: "Manchester United scout has viewed your profile",
    created_at: new Date().toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "2",
    type: "trial_invite",
    content: "Arsenal FC has invited you for a trial",
    created_at: new Date(Date.now() - 3600000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "3",
    type: "highlight_like",
    content: "Your latest highlight received 50+ likes",
    created_at: new Date(Date.now() - 86400000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "4",
    type: "profile_view",
    content: "5 scouts viewed your profile in the last 24 hours",
    created_at: new Date(Date.now() - 172800000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "5",
    type: "message",
    content: "New message from Chelsea FC Youth Academy",
    created_at: new Date(Date.now() - 259200000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "6",
    type: "achievement",
    content: "Congratulations! Your profile reached 1000 views",
    created_at: new Date(Date.now() - 345600000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "7",
    type: "highlight_share",
    content: "Your highlight was shared by a professional coach",
    created_at: new Date(Date.now() - 432000000).toISOString(),
    is_read: false,
    priority: "medium"
  },
  {
    id: "8",
    type: "trial_reminder",
    content: "Upcoming trial at Liverpool FC tomorrow",
    created_at: new Date(Date.now() - 518400000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "9",
    type: "profile_recommendation",
    content: "Update your profile with recent match statistics",
    created_at: new Date(Date.now() - 604800000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "10",
    type: "scout_message",
    content: "New scout feedback available",
    created_at: new Date(Date.now() - 691200000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "11",
    type: "highlight_trending",
    content: "Your highlight is trending in your region",
    created_at: new Date(Date.now() - 777600000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "12",
    type: "profile_milestone",
    content: "Your profile completion reached 90%",
    created_at: new Date(Date.now() - 864000000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "13",
    type: "trial_feedback",
    content: "New feedback from your recent trial",
    created_at: new Date(Date.now() - 950400000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "14",
    type: "connection_request",
    content: "Professional coach wants to connect",
    created_at: new Date(Date.now() - 1036800000).toISOString(),
    is_read: false,
    priority: "medium"
  },
  {
    id: "15",
    type: "highlight_comment",
    content: "A scout commented on your highlight",
    created_at: new Date(Date.now() - 1123200000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "16",
    type: "profile_alert",
    content: "Your profile needs attention in key areas",
    created_at: new Date(Date.now() - 1209600000).toISOString(),
    is_read: false,
    priority: "medium"
  },
  {
    id: "17",
    type: "achievement_unlock",
    content: "New achievement unlocked: Rising Star",
    created_at: new Date(Date.now() - 1296000000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "18",
    type: "scout_report",
    content: "New scouting report available",
    created_at: new Date(Date.now() - 1382400000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "19",
    type: "highlight_milestone",
    content: "Your highlights reached 10,000 views",
    created_at: new Date(Date.now() - 1468800000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "20",
    type: "profile_featured",
    content: "Your profile is featured this week",
    created_at: new Date(Date.now() - 1555200000).toISOString(),
    is_read: false,
    priority: "high"
  }
]

const mockMessages: Notification[] = [
  {
    id: "m1",
    type: "scout_message",
    content: "Hi, I'm impressed with your recent performances. Would love to discuss opportunities.",
    created_at: new Date().toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m2",
    type: "coach_message",
    content: "Your technical skills are impressive. Let's schedule a training session.",
    created_at: new Date(Date.now() - 86400000).toISOString(),
    is_read: false,
    priority: "medium"
  },
  {
    id: "m3",
    type: "trial_inquiry",
    content: "Following up on your trial performance last week. Can we schedule a call?",
    created_at: new Date(Date.now() - 172800000).toISOString(),
    is_read: true,
    priority: "high"
  },
  {
    id: "m4",
    type: "highlight_feedback",
    content: "Great movement in your latest highlight. Keep up the good work!",
    created_at: new Date(Date.now() - 259200000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "m5",
    type: "academy_inquiry",
    content: "Would you be interested in joining our youth development program?",
    created_at: new Date(Date.now() - 345600000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m6",
    type: "scout_feedback",
    content: "Watched your last three matches. Very promising development.",
    created_at: new Date(Date.now() - 432000000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "m7",
    type: "trial_opportunity",
    content: "Would you be available for a trial match next month?",
    created_at: new Date(Date.now() - 518400000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m8",
    type: "coach_feedback",
    content: "Your defensive positioning has improved significantly.",
    created_at: new Date(Date.now() - 604800000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "m9",
    type: "academy_update",
    content: "Update regarding your application to our summer program.",
    created_at: new Date(Date.now() - 691200000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m10",
    type: "highlight_request",
    content: "Could you share more highlights from your recent tournament?",
    created_at: new Date(Date.now() - 777600000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "m11",
    type: "scout_introduction",
    content: "Introduction from Premier League club scout.",
    created_at: new Date(Date.now() - 864000000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m12",
    type: "training_invitation",
    content: "Invitation to elite training camp next month.",
    created_at: new Date(Date.now() - 950400000).toISOString(),
    is_read: false,
    priority: "medium"
  },
  {
    id: "m13",
    type: "profile_inquiry",
    content: "Question about your previous experience with youth teams.",
    created_at: new Date(Date.now() - 1036800000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "m14",
    type: "trial_feedback",
    content: "Detailed feedback from your trial session last week.",
    created_at: new Date(Date.now() - 1123200000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m15",
    type: "coach_recommendation",
    content: "Your current coach left a strong recommendation.",
    created_at: new Date(Date.now() - 1209600000).toISOString(),
    is_read: true,
    priority: "medium"
  }
]

export default function Notifications() {
  const [activeTab, setActiveTab] = useState<"notifications" | "messages">("notifications")
  const [items, setItems] = useState<Notification[]>(mockNotifications)

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'scout_interest':
      case 'scout_message':
      case 'scout_report':
        return <Eye className="h-5 w-5 text-blue-500" />
      case 'trial_invite':
      case 'trial_reminder':
      case 'trial_feedback':
      case 'trial_opportunity':
        return <Calendar className="h-5 w-5 text-purple-500" />
      case 'highlight_like':
      case 'highlight_comment':
      case 'highlight_share':
      case 'highlight_trending':
      case 'highlight_milestone':
        return <Video className="h-5 w-5 text-red-500" />
      case 'profile_view':
      case 'profile_recommendation':
      case 'profile_milestone':
      case 'profile_alert':
      case 'profile_featured':
        return <UserPlus className="h-5 w-5 text-green-500" />
      case 'message':
      case 'coach_message':
      case 'academy_inquiry':
      case 'coach_feedback':
        return <MessageCircle className="h-5 w-5 text-indigo-500" />
      case 'achievement':
      case 'achievement_unlock':
        return <Trophy className="h-5 w-5 text-yellow-500" />
      case 'connection_request':
        return <UserPlus className="h-5 w-5 text-teal-500" />
      default:
        return <Bell className="h-5 w-5 text-gray-500" />
    }
  }

  const getPriorityColor = (priority?: "high" | "medium" | "low") => {
    switch (priority) {
      case "high":
        return "bg-red-50 dark:bg-red-900/20"
      case "medium":
        return "bg-yellow-50 dark:bg-yellow-900/20"
      case "low":
        return "bg-blue-50 dark:bg-blue-900/20"
      default:
        return "bg-gray-50 dark:bg-gray-900/20"
    }
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  const markAsRead = (id: string) => {
    setItems(items.map(item => 
      item.id === id ? { ...item, is_read: true } : item
    ))
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold mb-6 flex items-center gap-2">
        <Bell className="h-6 w-6" />
        Activity Center
      </h1>

      <div className="flex gap-4 mb-6">
        <button
          onClick={() => {
            setActiveTab("notifications")
            setItems(mockNotifications)
          }}
          className={`px-4 py-2 rounded-lg ${
            activeTab === "notifications"
              ? "bg-primary text-primary-foreground"
              : "bg-secondary text-secondary-foreground"
          }`}
        >
          Notifications
        </button>
        <button
          onClick={() => {
            setActiveTab("messages")
            setItems(mockMessages)
          }}
          className={`px-4 py-2 rounded-lg ${
            activeTab === "messages"
              ? "bg-primary text-primary-foreground"
              : "bg-secondary text-secondary-foreground"
          }`}
        >
          Messages
        </button>
      </div>

      <div className="space-y-4">
        {items.length === 0 ? (
          <div className="text-center py-8 text-muted-foreground">
            No {activeTab} yet
          </div>
        ) : (
          items.map((item) => (
            <div
              key={item.id}
              className={`glass-card rounded-lg p-4 transition-all ${
                item.is_read ? 'opacity-70' : ''
              } ${getPriorityColor(item.priority)}`}
            >
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0">
                  {getNotificationIcon(item.type)}
                </div>
                <div className="flex-grow">
                  <p className="text-foreground">{item.content}</p>
                  <div className="flex items-center gap-2 mt-2 text-sm text-muted-foreground">
                    {formatDate(item.created_at)}
                  </div>
                </div>
                {!item.is_read && (
                  <button
                    onClick={() => markAsRead(item.id)}
                    className="flex-shrink-0 text-sm text-primary hover:text-primary/80 transition-colors"
                  >
                    Mark as read
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}