import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Edit, ChevronLeft, ChevronRight } from "lucide-react"
import { useToast } from "@/components/ui/use-toast"
import { useRef, useState, useEffect } from "react"
import { HighlightsManagement } from "./HighlightsManagement"
import { supabase } from "@/lib/supabase"

interface HighlightsSectionProps {
  playerData: any
  isEditing?: boolean
}

interface Highlight {
  id: string
  title: string
  description: string
  video_url: string
  is_active: boolean
}

export function HighlightsSection({ playerData, isEditing }: HighlightsSectionProps) {
  const { toast } = useToast()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [currentHighlight, setCurrentHighlight] = useState(1)
  const [highlights, setHighlights] = useState<Highlight[]>([])
  
  useEffect(() => {
    const fetchHighlights = async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) return

      const { data, error } = await supabase
        .from('highlights')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_active', true)
        .order('position')

      if (error) {
        console.error('Error fetching highlights:', error)
        return
      }

      setHighlights(data || [])
    }

    fetchHighlights()
  }, [])

  const totalHighlights = highlights.length

  const handleEditClick = () => {
    toast({
      title: "Edit mode",
      description: "This feature is coming soon!"
    })
  }

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300
      const newScroll = direction === 'left' 
        ? scrollContainerRef.current.scrollLeft - scrollAmount
        : scrollContainerRef.current.scrollLeft + scrollAmount
      
      scrollContainerRef.current.scrollTo({
        left: newScroll,
        behavior: 'smooth'
      })

      const newHighlight = direction === 'left' 
        ? Math.max(currentHighlight - 1, 1)
        : Math.min(currentHighlight + 1, totalHighlights)
      setCurrentHighlight(newHighlight)
    }
  }

  if (isEditing) {
    return <HighlightsManagement />
  }

  return (
    <Card className="dashboard-card">
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-lg font-semibold">Featured Highlights</h2>
        <Button variant="ghost" size="sm" onClick={handleEditClick}>
          <Edit className="h-4 w-4" />
        </Button>
      </div>

      <div className="relative">
        <Button 
          variant="ghost" 
          size="icon"
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10"
          onClick={() => scroll('left')}
          disabled={currentHighlight === 1}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>

        <Button 
          variant="ghost" 
          size="icon"
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10"
          onClick={() => scroll('right')}
          disabled={currentHighlight === totalHighlights}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>

        <div className="absolute top-2 right-2 z-10 bg-background/80 backdrop-blur-sm px-2 py-1 rounded text-sm">
          {currentHighlight}/{totalHighlights}
        </div>

        <div 
          ref={scrollContainerRef}
          className="flex overflow-x-auto gap-4 pb-4 scrollbar-hide snap-x snap-mandatory"
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        >
          {highlights.length > 0 ? (
            highlights.map((highlight) => (
              <div 
                key={highlight.id} 
                className="min-w-[300px] flex-none snap-center"
              >
                <div className="space-y-2">
                  <div className="text-sm font-medium">
                    {highlight.title}
                  </div>
                  <div className="text-sm text-muted-foreground">
                    {highlight.description}
                  </div>
                  <div className="relative aspect-video bg-muted rounded-lg overflow-hidden">
                    {highlight.video_url ? (
                      <video 
                        src={highlight.video_url} 
                        controls 
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <p className="text-muted-foreground">No highlight video</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full text-center py-8 text-muted-foreground">
              No highlights added yet
            </div>
          )}
        </div>
      </div>

      <style>{`
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </Card>
  )
}