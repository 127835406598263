import {
  Bell,
  HelpCircle,
  Home,
  Inbox,
  Settings,
  User,
  Video,
  Users,
} from "lucide-react"
import { cn } from "@/lib/utils"
import { Link, useLocation } from "react-router-dom"

const navItems = [
  {
    title: "Home",
    icon: Home,
    href: "/home",
  },
  {
    title: "My Profile",
    icon: User,
    href: "/my-profile",
  },
  {
    title: "My Network",
    icon: Users,
    href: "/network",
  },
  {
    title: "Highlights",
    icon: Video,
    href: "/highlights",
  },
  {
    title: "Notifications",
    icon: Bell,
    href: "/notifications",
  },
  {
    title: "Messages",
    icon: Inbox,
    href: "/messages",
  },
  {
    title: "Settings",
    icon: Settings,
    href: "/settings",
  },
  {
    title: "Help & Support",
    icon: HelpCircle,
    href: "/support",
  },
]

export function DashboardNav() {
  const location = useLocation()
  console.log("Current location:", location.pathname)

  return (
    <nav className="space-y-1 p-4">
      {navItems.map((item) => {
        const isActive = location.pathname === item.href
        
        return (
          <Link
            key={item.href}
            to={item.href}
            className={cn(
              "nav-item",
              isActive ? "nav-item-active" : "nav-item-default"
            )}
          >
            <item.icon className="nav-icon" />
            <span>{item.title}</span>
          </Link>
        )
      })}
    </nav>
  )
}