
import { useState, useEffect } from "react"
import { supabase } from "@/lib/supabase"
import { useToast } from "@/hooks/use-toast"

type LocationData = {
  latitude?: number
  longitude?: number
  city?: string
  country?: string
}

export const useLocation = () => {
  const [locationData, setLocationData] = useState<LocationData | null>(null)
  const { toast } = useToast()

  const getUserLocation = async () => {
    console.log("Starting location request...")
    
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by this browser")
      return
    }

    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        })
      })

      const { latitude, longitude } = position.coords
      console.log("Got coordinates:", { latitude, longitude })

      console.log("Calling geocode function...")
      const { data: geocodeData, error } = await supabase.functions.invoke('geocode', {
        body: { latitude, longitude }
      })

      if (error) {
        console.error("Geocoding error:", error)
        return
      }

      console.log("Geocode response:", geocodeData)
      
      const features = geocodeData.features
      let city = ""
      let country = ""
      
      features.forEach((feature: any) => {
        if (feature.place_type.includes("place")) {
          city = feature.text
        }
        if (feature.place_type.includes("country")) {
          country = feature.text
        }
      })

      console.log("Extracted location data:", { city, country })

      const locationInfo = {
        latitude,
        longitude,
        city,
        country
      }

      console.log("Setting location data:", locationInfo)
      setLocationData(locationInfo)

      toast({
        title: "Location Access Granted",
        description: "Thank you for sharing your location!",
        duration: 3000,
      })
    } catch (error) {
      console.error("Error getting location:", error)
      toast({
        title: "Location Access",
        description: "Location access helps us better understand our user base. You can still complete the survey without sharing your location.",
        duration: 5000,
      })
    }
  }

  useEffect(() => {
    getUserLocation()
  }, [])

  return locationData
}
