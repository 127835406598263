import { Card } from "@/components/ui/card"
import { Calendar, MapPin } from "lucide-react"

interface AvailabilitySectionProps {
  playerData: any;
}

export function AvailabilitySection({ playerData }: AvailabilitySectionProps) {
  return (
    <Card className="p-6">
      <h2 className="text-lg font-semibold mb-4">Availability</h2>
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Calendar className="h-4 w-4 text-muted-foreground" />
          <div>
            <p className="text-sm text-muted-foreground">Open to Trials</p>
            <p className="font-medium">{playerData?.openToTrials === 'yes' ? 'Yes' : 'No'}</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <MapPin className="h-4 w-4 text-muted-foreground" />
          <div>
            <p className="text-sm text-muted-foreground">Willing to Relocate</p>
            <p className="font-medium">{playerData?.willingToRelocate === 'yes' ? 'Yes' : 'No'}</p>
          </div>
        </div>
      </div>
    </Card>
  )
}