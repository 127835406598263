import { useState } from "react"
import { 
  MessageCircle, 
  User,
  Trophy,
  Video,
  Mail,
  Star
} from "lucide-react"

interface Message {
  id: string
  type: string
  content: string
  sender: string
  created_at: string
  is_read: boolean
  priority?: "high" | "medium" | "low"
}

const mockMessages: Message[] = [
  {
    id: "m1",
    type: "scout_message",
    sender: "Premier League Scout",
    content: "Hi, I'm impressed with your recent performances. Would love to discuss opportunities.",
    created_at: new Date().toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m2",
    type: "coach_message",
    sender: "Elite Academy Coach",
    content: "Your technical skills are impressive. Let's schedule a training session.",
    created_at: new Date(Date.now() - 86400000).toISOString(),
    is_read: false,
    priority: "medium"
  },
  {
    id: "m3",
    type: "trial_inquiry",
    sender: "Manchester City Academy",
    content: "Following up on your trial performance last week. Can we schedule a call?",
    created_at: new Date(Date.now() - 172800000).toISOString(),
    is_read: true,
    priority: "high"
  },
  {
    id: "m4",
    type: "highlight_feedback",
    sender: "Technical Director",
    content: "Great movement in your latest highlight. Keep up the good work!",
    created_at: new Date(Date.now() - 259200000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "m5",
    type: "academy_inquiry",
    sender: "Ajax Youth Academy",
    content: "Would you be interested in joining our youth development program?",
    created_at: new Date(Date.now() - 345600000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m6",
    type: "scout_feedback",
    sender: "Liverpool FC Scout",
    content: "Watched your last three matches. Very promising development.",
    created_at: new Date(Date.now() - 432000000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "m7",
    type: "trial_opportunity",
    sender: "Arsenal Academy",
    content: "Would you be available for a trial match next month?",
    created_at: new Date(Date.now() - 518400000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m8",
    type: "coach_feedback",
    sender: "National Team Coach",
    content: "Your defensive positioning has improved significantly.",
    created_at: new Date(Date.now() - 604800000).toISOString(),
    is_read: true,
    priority: "medium"
  },
  {
    id: "m9",
    type: "academy_update",
    sender: "Barcelona La Masia",
    content: "Update regarding your application to our summer program.",
    created_at: new Date(Date.now() - 691200000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m10",
    type: "highlight_request",
    sender: "Talent Scout",
    content: "Could you share more highlights from your recent tournament?",
    created_at: new Date(Date.now() - 777600000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "m11",
    type: "scout_introduction",
    sender: "Chelsea FC Scout",
    content: "Introduction from Premier League club scout.",
    created_at: new Date(Date.now() - 864000000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m12",
    type: "training_invitation",
    sender: "Elite Training Camp",
    content: "Invitation to elite training camp next month.",
    created_at: new Date(Date.now() - 950400000).toISOString(),
    is_read: false,
    priority: "medium"
  },
  {
    id: "m13",
    type: "profile_inquiry",
    sender: "Youth Team Manager",
    content: "Question about your previous experience with youth teams.",
    created_at: new Date(Date.now() - 1036800000).toISOString(),
    is_read: true,
    priority: "low"
  },
  {
    id: "m14",
    type: "trial_feedback",
    sender: "Academy Director",
    content: "Detailed feedback from your trial session last week.",
    created_at: new Date(Date.now() - 1123200000).toISOString(),
    is_read: false,
    priority: "high"
  },
  {
    id: "m15",
    type: "coach_recommendation",
    sender: "Current Coach",
    content: "Your current coach left a strong recommendation.",
    created_at: new Date(Date.now() - 1209600000).toISOString(),
    is_read: true,
    priority: "medium"
  }
]

export default function Messages() {
  const [messages, setMessages] = useState<Message[]>(mockMessages)

  const getMessageIcon = (type: string) => {
    switch (type) {
      case 'scout_message':
      case 'scout_feedback':
      case 'scout_introduction':
        return <Star className="h-5 w-5 text-yellow-500" />
      case 'trial_inquiry':
      case 'trial_opportunity':
      case 'trial_feedback':
        return <Trophy className="h-5 w-5 text-purple-500" />
      case 'highlight_feedback':
      case 'highlight_request':
        return <Video className="h-5 w-5 text-red-500" />
      case 'coach_message':
      case 'coach_feedback':
      case 'coach_recommendation':
        return <User className="h-5 w-5 text-blue-500" />
      case 'academy_inquiry':
      case 'academy_update':
      case 'training_invitation':
        return <Mail className="h-5 w-5 text-green-500" />
      default:
        return <MessageCircle className="h-5 w-5 text-gray-500" />
    }
  }

  const getPriorityColor = (priority?: "high" | "medium" | "low") => {
    switch (priority) {
      case "high":
        return "bg-red-50 dark:bg-red-900/20"
      case "medium":
        return "bg-yellow-50 dark:bg-yellow-900/20"
      case "low":
        return "bg-blue-50 dark:bg-blue-900/20"
      default:
        return "bg-gray-50 dark:bg-gray-900/20"
    }
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  const markAsRead = (id: string) => {
    setMessages(messages.map(message => 
      message.id === id ? { ...message, is_read: true } : message
    ))
  }

  return (
    <div className="space-y-4">
      {messages.length === 0 ? (
        <div className="text-center py-8 text-muted-foreground">
          No messages yet
        </div>
      ) : (
        messages.map((message) => (
          <div
            key={message.id}
            className={`glass-card rounded-lg p-4 transition-all ${
              message.is_read ? 'opacity-70' : ''
            } ${getPriorityColor(message.priority)}`}
          >
            <div className="flex items-start gap-4">
              <div className="flex-shrink-0">
                {getMessageIcon(message.type)}
              </div>
              <div className="flex-grow">
                <div className="flex justify-between items-start">
                  <h3 className="font-medium text-foreground">{message.sender}</h3>
                  <span className="text-sm text-muted-foreground">
                    {formatDate(message.created_at)}
                  </span>
                </div>
                <p className="text-foreground mt-1">{message.content}</p>
              </div>
              {!message.is_read && (
                <button
                  onClick={() => markAsRead(message.id)}
                  className="flex-shrink-0 text-sm text-primary hover:text-primary/80 transition-colors"
                >
                  Mark as read
                </button>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  )
}