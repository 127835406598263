
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { CurrencyProvider } from "@/contexts/CurrencyContext"
import { PlayerDashboardLayout } from "@/components/dashboard/PlayerDashboardLayout"
import Analytics from "@/pages/Analytics"
import Login from "@/pages/Login"
import Index from "@/pages/Index"
import LearnMore from "@/pages/LearnMore"
import { PlayerProfileForm } from "@/components/profile/PlayerProfileForm"
import Home from "@/pages/Home"
import MyProfile from "@/pages/MyProfile"
import Network from "@/pages/Network"
import UnderConstructionPage from "@/components/placeholder/UnderConstructionPage"
import Highlights from "@/pages/Highlights"
import Notifications from "@/pages/Notifications"
import Messages from "@/pages/Messages"
import { Outlet } from "react-router-dom"
import ScoutSurvey from "@/pages/ScoutSurvey"

function App() {
  console.log("App component rendering")
  
  return (
    <BrowserRouter basename="/">
      <CurrencyProvider>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/learn-more" element={<LearnMore />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-player-profile" element={<PlayerProfileForm />} />
          <Route path="/scout-survey" element={<ScoutSurvey />} />
          
          {/* Player routes with PlayerDashboardLayout */}
          <Route element={<PlayerDashboardLayout><Outlet /></PlayerDashboardLayout>}>
            <Route path="/home" element={<Home />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/network" element={<Network />} />
            <Route path="/highlights" element={<Highlights />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/settings" element={<UnderConstructionPage />} />
            <Route path="/support" element={<UnderConstructionPage />} />
            <Route path="/stats" element={<Analytics />} />
            <Route path="/analytics" element={<Analytics />} />
          </Route>
        </Routes>
      </CurrencyProvider>
    </BrowserRouter>
  )
}

export default App
