import { FormField, FormItem, FormLabel, FormControl, FormDescription } from "@/components/ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { InfoIcon } from "lucide-react"
import { Textarea } from "@/components/ui/textarea"

export function InterestsForm({ form }: { form: any }) {
  return (
    <div className="space-y-6">
      <Alert>
        <InfoIcon className="h-4 w-4" />
        <AlertDescription>
          While it's natural to aspire to play in top leagues, being realistic about your current level and progression path will help attract genuine opportunities that match your development stage.
        </AlertDescription>
      </Alert>

      <FormField
        control={form.control}
        name="preferredLeagues"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Preferred Leagues</FormLabel>
            <FormDescription>
              Select leagues where you believe you could realistically contribute based on your current level
            </FormDescription>
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger>
                <SelectValue placeholder="Select preferred league level" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="premier">Premier League / Top Division</SelectItem>
                <SelectItem value="championship">Championship / Second Division</SelectItem>
                <SelectItem value="league1">League One / Third Division</SelectItem>
                <SelectItem value="league2">League Two / Fourth Division</SelectItem>
                <SelectItem value="national">National League / Semi-Professional</SelectItem>
                <SelectItem value="regional">Regional Leagues</SelectItem>
              </SelectContent>
            </Select>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="careerGoals"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Career Goals & Expectations</FormLabel>
            <FormDescription>
              Describe your short-term and long-term career goals
            </FormDescription>
            <FormControl>
              <Textarea 
                placeholder="Share your realistic career progression goals..."
                className="min-h-[100px]"
                {...field}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  )
}