
import { Card } from "@/components/ui/card"

export const ThankYouScreen = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Card className="p-6">
          <div className="text-center space-y-4">
            <h2 className="text-2xl font-bold text-green-600">Thank You for Your Response!</h2>
            <p className="text-gray-600">
              Your feedback is valuable and will help us improve Draft'd for scouts like you.
            </p>
            <p className="text-sm text-gray-500">
              We'll keep you updated on our progress and new features.
            </p>
          </div>
        </Card>
      </div>
    </div>
  )
}
