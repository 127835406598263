import { FileUpload } from "@/components/FileUpload"
import { FormDescription, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { InfoIcon, AlertTriangle } from "lucide-react"

export function ProfilePictureForm({ form }: { form: any }) {
  console.log("Rendering ProfilePictureForm")
  
  return (
    <div className="space-y-6">
      <Alert variant="default">
        <InfoIcon className="h-4 w-4" />
        <AlertTitle>Important</AlertTitle>
        <AlertDescription>
          A clear profile picture helps scouts identify you and makes your profile more professional.
          Upload a high-quality headshot photo.
        </AlertDescription>
      </Alert>

      {!form.getValues('profilePicture') && (
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Required</AlertTitle>
          <AlertDescription>
            A profile picture is required to complete your profile. Profiles without pictures receive significantly less attention from scouts.
          </AlertDescription>
        </Alert>
      )}

      <FormField
        control={form.control}
        name="profilePicture"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Profile Picture</FormLabel>
            <FormDescription>
              Upload a clear headshot photo (recommended size: 400x400px)
            </FormDescription>
            <FileUpload onUpload={(files) => {
              if (files?.[0]) {
                field.onChange(files[0])
                console.log("Profile picture uploaded:", files[0].name)
              }
            }} />
          </FormItem>
        )}
      />
    </div>
  )
}