import { Construction } from "lucide-react"

export const CTASection = () => {
  return (
    <section className="py-24 px-6 bg-background">
      <div className="max-w-3xl mx-auto text-center">
        <div className="dashboard-card p-12">
          <Construction className="w-16 h-16 text-primary mx-auto mb-6 animate-pulse" />
          <h2 className="text-3xl font-semibold mb-4">Waitlist Opening Soon</h2>
          <p className="text-xl text-muted-foreground">
            We're putting the finishing touches on something special. Check back soon to join our exclusive waitlist.
          </p>
        </div>
      </div>
    </section>
  )
}