
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { Checkbox } from "@/components/ui/checkbox"
import { Textarea } from "@/components/ui/textarea"
import { UseFormReturn } from "react-hook-form"
import { FormValues } from "../schema"
import { platformConcerns } from "../constants"

interface PlatformSectionProps {
  form: UseFormReturn<FormValues>
}

export const PlatformSection = ({ form }: PlatformSectionProps) => {
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">Draft'd as a Solution</h2>
      
      <div className="space-y-4">
        <Label>
          If a platform existed where players could create verified profiles with highlight clips and club history,
          would that help your scouting?
        </Label>
        <RadioGroup
          onValueChange={(value) => form.setValue("platform_interest", value as any)}
          className="grid gap-4"
        >
          <div>
            <RadioGroupItem value="yes_active" id="yes_active" />
            <Label htmlFor="yes_active">Yes, I would use it actively</Label>
          </div>
          <div>
            <RadioGroupItem value="maybe_unsure" id="maybe_unsure" />
            <Label htmlFor="maybe_unsure">Maybe, I'd try it but unsure</Label>
          </div>
          <div>
            <RadioGroupItem value="not_useful" id="not_useful" />
            <Label htmlFor="not_useful">No, I don't think it would be useful</Label>
          </div>
        </RadioGroup>
        {form.formState.errors.platform_interest && (
          <p className="text-sm text-red-500">{form.formState.errors.platform_interest.message}</p>
        )}
      </div>

      <div className="space-y-4">
        <Label>What concerns, if any, would you have about using a platform like Draft'd? (Select all that apply)</Label>
        <div className="grid gap-2">
          {platformConcerns.map((concern) => (
            <div key={concern.id} className="flex items-center space-x-2">
              <Checkbox
                id={concern.id}
                onCheckedChange={(checked) => {
                  const current = form.getValues("platform_concerns")
                  if (checked) {
                    form.setValue("platform_concerns", [...current, concern.id])
                  } else {
                    form.setValue(
                      "platform_concerns",
                      current.filter((id) => id !== concern.id)
                    )
                  }
                }}
              />
              <Label htmlFor={concern.id}>{concern.label}</Label>
            </div>
          ))}
        </div>
        {form.formState.errors.platform_concerns && (
          <p className="text-sm text-red-500">{form.formState.errors.platform_concerns.message}</p>
        )}
        
        <div className="space-y-2">
          <Label htmlFor="other_concern">Other (please specify)</Label>
          <Textarea
            id="other_concern"
            placeholder="Enter your other concerns here..."
            onChange={(e) => form.setValue("other_platform_concern", e.target.value)}
          />
        </div>
      </div>
      
      <div className="space-y-4">
        <Label>Would you pay for a platform that gives you access to highly targeted players who match your criteria?</Label>
        <RadioGroup
          onValueChange={(value) => form.setValue("payment_willingness", value as any)}
          className="grid gap-4"
        >
          <div>
            <RadioGroupItem value="yes_would_pay" id="yes_would_pay" />
            <Label htmlFor="yes_would_pay">Yes, if it made scouting easier</Label>
          </div>
          <div>
            <RadioGroupItem value="maybe_depends" id="maybe_depends" />
            <Label htmlFor="maybe_depends">Maybe, but depends on the cost</Label>
          </div>
          <div>
            <RadioGroupItem value="no_would_not_pay" id="no_would_not_pay" />
            <Label htmlFor="no_would_not_pay">No, I wouldn't pay for this</Label>
          </div>
        </RadioGroup>
        {form.formState.errors.payment_willingness && (
          <p className="text-sm text-red-500">{form.formState.errors.payment_willingness.message}</p>
        )}
      </div>
    </div>
  )
}
