import React, { createContext, useContext, useState } from 'react';

type CurrencyContextType = {
  currency: string;
  setCurrency: (currency: string) => void;
  convertValue: (value: number) => string;
};

const CurrencyContext = createContext<CurrencyContextType | undefined>(undefined);

export function CurrencyProvider({ children }: { children: React.ReactNode }) {
  const [currency, setCurrency] = useState('€');

  // Exchange rates (simplified for demo)
  const rates: { [key: string]: number } = {
    '€': 1,
    '$': 1.1,
    '£': 0.86
  };

  const convertValue = (value: number): string => {
    console.log('Converting value:', value, 'from EUR to', currency);
    if (!value) return `${currency}0.00M`;
    
    const convertedValue = value * rates[currency];
    console.log('Converted value:', convertedValue);
    return `${currency}${convertedValue.toFixed(2)}M`;
  };

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, convertValue }}>
      {children}
    </CurrencyContext.Provider>
  );
}

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};