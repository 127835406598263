import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Edit } from "lucide-react"
import { useToast } from "@/components/ui/use-toast"

interface PositionExperienceSectionProps {
  playerData: {
    position?: string;
    currentClub?: string;
    previousClubs?: string;
    dominantFoot?: string;
  };
}

export function PositionExperienceSection({ playerData }: PositionExperienceSectionProps) {
  const { toast } = useToast()
  
  const handleEditClick = () => {
    toast({
      title: "Edit mode",
      description: "This feature is coming soon!"
    })
  }

  return (
    <Card className="dashboard-card">
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-lg font-semibold">Position & Experience</h2>
        <Button variant="ghost" size="sm" onClick={handleEditClick}>
          <Edit className="h-4 w-4" />
        </Button>
      </div>
      <div className="space-y-4">
        {playerData?.position && (
          <div>
            <p className="text-sm text-muted-foreground">Position</p>
            <p className="font-medium">{playerData.position}</p>
          </div>
        )}
        {playerData?.currentClub && (
          <div>
            <p className="text-sm text-muted-foreground">Current Club</p>
            <p className="font-medium">{playerData.currentClub}</p>
          </div>
        )}
        {playerData?.previousClubs && (
          <div>
            <p className="text-sm text-muted-foreground">Previous Clubs</p>
            <p className="font-medium">{playerData.previousClubs}</p>
          </div>
        )}
        {playerData?.dominantFoot && (
          <div>
            <p className="text-sm text-muted-foreground">Dominant Foot</p>
            <p className="font-medium">{playerData.dominantFoot}</p>
          </div>
        )}
      </div>
    </Card>
  )
}