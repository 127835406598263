import { MessageSquare, Search, Trophy, Video } from "lucide-react"
import { Card } from "./ui/card"

const features = [
  {
    icon: <Video className="h-12 w-12 text-primary" />,
    title: "Showcase Your Skills",
    description: "Share your stats, achievements, and video highlights to stand out.",
  },
  {
    icon: <Search className="h-12 w-12 text-primary" />,
    title: "Get Noticed",
    description: "Gain visibility with scouts and clubs worldwide through our platform.",
  },
  {
    icon: <MessageSquare className="h-12 w-12 text-primary" />,
    title: "Direct Communication",
    description: "Connect directly with scouts and take control of your career.",
  },
  {
    icon: <Trophy className="h-12 w-12 text-primary" />,
    title: "Track Progress",
    description: "Monitor your profile performance and scout interactions.",
  },
]

const Features = () => {
  return (
    <section className="py-32 px-6 bg-background">
      <div className="max-w-6xl mx-auto text-center mb-16">
        <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/80">
          Why Choose Draft'd
        </h2>
        <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
          Everything you need to showcase your talent and connect with opportunities worldwide
        </p>
      </div>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {features.map((feature, index) => (
          <Card 
            key={index}
            className="dashboard-card hover:translate-y-[-4px] [animation-delay:200ms]"
          >
            <div className="flex flex-col items-center text-center">
              <div className="mb-6 p-4 rounded-full bg-primary/5">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
              <p className="text-muted-foreground">{feature.description}</p>
            </div>
          </Card>
        ))}
      </div>
    </section>
  )
}

export default Features