
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { Card } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { supabase } from "@/lib/supabase"
import { useToast } from "@/hooks/use-toast"
import { Loader2 } from "lucide-react"
import { ThankYouScreen } from "@/features/scout-survey/components/ThankYouScreen"
import { formSchema, type FormValues } from "@/features/scout-survey/schema"
import { BackgroundSection } from "@/features/scout-survey/components/BackgroundSection"
import { ChallengesSection } from "@/features/scout-survey/components/ChallengesSection"
import { PlatformSection } from "@/features/scout-survey/components/PlatformSection"
import { useLocation } from "@/features/scout-survey/hooks/useLocation"

const ScoutSurvey = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const locationData = useLocation()
  const { toast } = useToast()
  
  console.log("[ScoutSurvey] Component rendering")
  
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      discovery_methods: [],
      scouting_challenges: [],
      platform_concerns: [],
      age_groups: [],
      other_platform_concern: "",
      difficulty_finding_players: 5,
      feature_rankings: {},
    },
    mode: "onSubmit"
  })

  const scrollToFirstError = (errors: any) => {
    console.log("[ScoutSurvey] Scrolling to first error:", errors)
    const firstErrorKey = Object.keys(errors)[0]
    console.log("[ScoutSurvey] First error key:", firstErrorKey)
    
    const elementId = `section-${firstErrorKey.replace(/_/g, '-').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}`
    console.log("[ScoutSurvey] Looking for element with ID:", elementId)
    
    const element = document.getElementById(elementId)
    
    if (element) {
      const offset = 200
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
      
      console.log("[ScoutSurvey] Scrolled to element:", elementId, "at position:", offsetPosition)
    } else {
      console.error("[ScoutSurvey] Element not found for error key:", firstErrorKey)
    }
  }
  
  const onSubmit = async (data: FormValues) => {
    console.log("[ScoutSurvey] Form submission started")
    console.log("[ScoutSurvey] Form values before submission:", JSON.stringify(data, null, 2))
    console.log("[ScoutSurvey] Form validation state:", form.formState)
    
    try {
      setIsSubmitting(true)
      console.log("[ScoutSurvey] Setting isSubmitting to true")
      
      const submissionData = {
        ...data,
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        location_city: locationData?.city,
        location_country: locationData?.country,
        feature_rankings: data.feature_rankings || {},
      }

      console.log("[ScoutSurvey] Prepared submission data:", JSON.stringify(submissionData, null, 2))

      const { error } = await supabase
        .from("scout_survey_responses")
        .insert([submissionData])
      
      if (error) {
        console.error("[ScoutSurvey] Supabase error:", error)
        throw error
      }
      
      console.log("[ScoutSurvey] Submission successful")
      
      toast({
        title: "Survey submitted successfully",
        description: "Thank you for your feedback!",
      })
      
      setIsSubmitted(true)
      console.log("[ScoutSurvey] Setting isSubmitted to true")
    } catch (error) {
      console.error("[ScoutSurvey] Error submitting survey:", error)
      toast({
        title: "Error submitting survey",
        description: "Please try again later.",
        variant: "destructive",
      })
    } finally {
      setIsSubmitting(false)
      console.log("[ScoutSurvey] Setting isSubmitting back to false")
    }
  }
  
  const onError = (errors: any) => {
    console.log("[ScoutSurvey] Form validation errors:", errors)
    console.log("[ScoutSurvey] Current form values:", JSON.stringify(form.getValues(), null, 2))
    scrollToFirstError(errors)
    toast({
      title: "Please complete all required fields",
      description: "Some questions need to be answered before submitting.",
      variant: "destructive",
    })
  }
  
  if (isSubmitted) {
    return <ThankYouScreen />
  }
  
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Card className="p-6">
          <h1 className="text-3xl font-bold mb-8">Scout Survey</h1>
          
          <Form {...form}>
            <form 
              onSubmit={(e) => {
                console.log("[ScoutSurvey] Form submit event triggered")
                form.handleSubmit(onSubmit, onError)(e)
              }} 
              className="space-y-8"
            >
              <div id="section-email" className="space-y-4">
                <Label htmlFor="email">Email Address</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email address"
                  {...form.register("email")}
                />
                {form.formState.errors.email && (
                  <p className="text-sm text-red-500">{form.formState.errors.email.message}</p>
                )}
              </div>

              <BackgroundSection form={form} />
              <ChallengesSection form={form} />
              <PlatformSection form={form} />
              
              <Button 
                type="submit" 
                className="w-full" 
                disabled={isSubmitting}
                onClick={() => console.log("[ScoutSurvey] Submit button clicked")}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Submitting...
                  </>
                ) : (
                  "Submit Survey"
                )}
              </Button>
            </form>
          </Form>
        </Card>
      </div>
    </div>
  )
}

export default ScoutSurvey
