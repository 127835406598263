import { HeroSection } from "@/components/learn-more/HeroSection"
import { AthleteFeatures } from "@/components/learn-more/AthleteFeatures"
import { ScoutFeatures } from "@/components/learn-more/ScoutFeatures"
import { CTASection } from "@/components/learn-more/CTASection"

const LearnMore = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-accent/10">
      <HeroSection />
      <AthleteFeatures />
      <ScoutFeatures />
      <CTASection />
    </div>
  )
}

export default LearnMore