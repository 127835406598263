
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { Checkbox } from "@/components/ui/checkbox"
import { UseFormReturn } from "react-hook-form"
import { FormValues } from "../schema"
import { discoveryMethods, ageGroups } from "../constants"

interface BackgroundSectionProps {
  form: UseFormReturn<FormValues>
}

export const BackgroundSection = ({ form }: BackgroundSectionProps) => {
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">Background & Scouting Process</h2>
      
      <div className="space-y-4">
        <Label>What level of football do you primarily scout for?</Label>
        <RadioGroup
          onValueChange={(value) => form.setValue("scouting_level", value as any)}
          className="grid grid-cols-2 gap-4"
        >
          <div>
            <RadioGroupItem value="grassroots" id="grassroots" />
            <Label htmlFor="grassroots">Grassroots</Label>
          </div>
          <div>
            <RadioGroupItem value="semi_pro" id="semi_pro" />
            <Label htmlFor="semi_pro">Semi-Pro</Label>
          </div>
          <div>
            <RadioGroupItem value="professional" id="professional" />
            <Label htmlFor="professional">Professional (League Level)</Label>
          </div>
          <div>
            <RadioGroupItem value="international" id="international" />
            <Label htmlFor="international">International</Label>
          </div>
        </RadioGroup>
        {form.formState.errors.scouting_level && (
          <p className="text-sm text-red-500">{form.formState.errors.scouting_level.message}</p>
        )}
      </div>

      <div className="space-y-4">
        <Label>What ages do you scout for? (Select all that apply)</Label>
        <div className="grid gap-2">
          {ageGroups.map((age) => (
            <div key={age.id} className="flex items-center space-x-2">
              <Checkbox
                id={age.id}
                onCheckedChange={(checked) => {
                  const current = form.getValues("age_groups")
                  if (checked) {
                    form.setValue("age_groups", [...current, age.id])
                  } else {
                    form.setValue(
                      "age_groups",
                      current.filter((id) => id !== age.id)
                    )
                  }
                }}
              />
              <Label htmlFor={age.id}>{age.label}</Label>
            </div>
          ))}
        </div>
        {form.formState.errors.age_groups && (
          <p className="text-sm text-red-500">{form.formState.errors.age_groups.message}</p>
        )}
      </div>
      
      <div className="space-y-4">
        <Label>How do you typically discover new talent?</Label>
        <div className="grid gap-2">
          {discoveryMethods.map((method) => (
            <div key={method.id} className="flex items-center space-x-2">
              <Checkbox
                id={method.id}
                onCheckedChange={(checked) => {
                  const current = form.getValues("discovery_methods")
                  if (checked) {
                    form.setValue("discovery_methods", [...current, method.id])
                  } else {
                    form.setValue(
                      "discovery_methods",
                      current.filter((id) => id !== method.id)
                    )
                  }
                }}
              />
              <Label htmlFor={method.id}>{method.label}</Label>
            </div>
          ))}
        </div>
        {form.formState.errors.discovery_methods && (
          <p className="text-sm text-red-500">{form.formState.errors.discovery_methods.message}</p>
        )}
      </div>
      
      <div className="space-y-4">
        <Label>How many new players do you typically scout per season?</Label>
        <RadioGroup
          onValueChange={(value) => form.setValue("players_scouted_range", value as any)}
          className="grid grid-cols-2 gap-4"
        >
          <div>
            <RadioGroupItem value="1-10" id="1-10" />
            <Label htmlFor="1-10">1-10</Label>
          </div>
          <div>
            <RadioGroupItem value="11-30" id="11-30" />
            <Label htmlFor="11-30">11-30</Label>
          </div>
          <div>
            <RadioGroupItem value="31-50" id="31-50" />
            <Label htmlFor="31-50">31-50</Label>
          </div>
          <div>
            <RadioGroupItem value="50+" id="50+" />
            <Label htmlFor="50+">50+</Label>
          </div>
        </RadioGroup>
        {form.formState.errors.players_scouted_range && (
          <p className="text-sm text-red-500">{form.formState.errors.players_scouted_range.message}</p>
        )}
      </div>
    </div>
  )
}
