import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Check, X } from "lucide-react"

interface NetworkContact {
  id: string
  name: string
  position: string
  club: string
  avatarUrl?: string
  connectionDate?: string
}

const demoContacts: NetworkContact[] = Array.from({ length: 15 }, (_, i) => ({
  id: `contact-${i}`,
  name: `Player ${i + 1}`,
  position: ["Forward", "Midfielder", "Defender", "Goalkeeper"][Math.floor(Math.random() * 4)],
  club: ["Manchester United", "Real Madrid", "Bayern Munich", "PSG", "Barcelona"][Math.floor(Math.random() * 5)],
  avatarUrl: undefined,
  connectionDate: `${Math.floor(Math.random() * 4) + 1} weeks ago`
}))

export default function Network() {
  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">My Network</h1>
        <Button variant="outline">Find Connections</Button>
      </div>

      <Tabs defaultValue="connections" className="w-full">
        <TabsList className="w-full justify-start mb-6">
          <TabsTrigger value="connections">Connections (15)</TabsTrigger>
          <TabsTrigger value="requests">Requests (15)</TabsTrigger>
          <TabsTrigger value="sent">Sent (15)</TabsTrigger>
        </TabsList>

        <TabsContent value="connections">
          <div className="grid gap-4">
            {demoContacts.map((contact) => (
              <ConnectionCard
                key={contact.id}
                contact={contact}
                type="connection"
              />
            ))}
          </div>
        </TabsContent>

        <TabsContent value="requests">
          <div className="grid gap-4">
            {demoContacts.map((contact) => (
              <ConnectionCard
                key={contact.id}
                contact={contact}
                type="request"
              />
            ))}
          </div>
        </TabsContent>

        <TabsContent value="sent">
          <div className="grid gap-4">
            {demoContacts.map((contact) => (
              <ConnectionCard
                key={contact.id}
                contact={contact}
                type="sent"
              />
            ))}
          </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}

function ConnectionCard({ 
  contact, 
  type 
}: { 
  contact: NetworkContact
  type: "connection" | "request" | "sent"
}) {
  return (
    <Card className="p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Avatar className="h-12 w-12">
            <AvatarImage src={contact.avatarUrl} />
            <AvatarFallback>{contact.name.slice(0, 2)}</AvatarFallback>
          </Avatar>
          <div>
            <h3 className="font-medium">{contact.name}</h3>
            <p className="text-sm text-muted-foreground">
              {contact.position} at {contact.club}
            </p>
            {contact.connectionDate && (
              <p className="text-xs text-muted-foreground mt-1">
                Connected {contact.connectionDate}
              </p>
            )}
          </div>
        </div>
        
        <div className="flex gap-2">
          {type === "connection" && (
            <Button variant="outline" size="sm">Message</Button>
          )}
          {type === "request" && (
            <>
              <Button variant="default" size="sm">
                <Check className="h-4 w-4 mr-1" />
                Accept
              </Button>
              <Button variant="outline" size="sm">
                <X className="h-4 w-4 mr-1" />
                Ignore
              </Button>
            </>
          )}
          {type === "sent" && (
            <Button variant="outline" size="sm">Pending</Button>
          )}
        </div>
      </div>
    </Card>
  )
}