import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"

export function ReferencesForm({ form }: { form: any }) {
  return (
    <div className="space-y-6">
      <FormField
        control={form.control}
        name="coachName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Coach/Trainer Name</FormLabel>
            <FormControl>
              <Input placeholder="Enter coach or trainer name" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="coachContact"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Coach/Trainer Contact</FormLabel>
            <FormControl>
              <Input placeholder="Enter coach or trainer contact details" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}