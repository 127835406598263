import { useState, useEffect } from "react"
import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { useToast } from "@/components/ui/use-toast"
import { Trash2, Share2, Plus, Lock } from "lucide-react"
import { supabase } from "@/integrations/supabase/client"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { FileUpload } from "@/components/FileUpload"

interface Highlight {
  id: string
  title: string
  description: string
  video_url: string
  position: number
  is_active: boolean
}

const MAX_FREE_HIGHLIGHTS = 5
const BASIC_PACKAGE_PRICE = 10
const BASIC_PACKAGE_LIMIT = 10

export function HighlightsManagement() {
  const [highlights, setHighlights] = useState<Highlight[]>([])
  const [isAddingHighlight, setIsAddingHighlight] = useState(false)
  const [newHighlight, setNewHighlight] = useState({
    title: "",
    description: "",
    video_url: "",
  })
  const { toast } = useToast()

  useEffect(() => {
    fetchHighlights()
  }, [])

  const fetchHighlights = async () => {
    const { data: user } = await supabase.auth.getUser()
    if (!user.user) return

    const { data, error } = await supabase
      .from("highlights")
      .select("*")
      .eq("user_id", user.user.id)
      .order("position")

    if (error) {
      console.error("Error fetching highlights:", error)
      return
    }

    setHighlights(data || [])
  }

  const handleAddHighlight = async () => {
    if (highlights.length >= MAX_FREE_HIGHLIGHTS) {
      toast({
        title: "Upgrade Required",
        description: `Upgrade to our Basic package for $${BASIC_PACKAGE_PRICE}/month to add up to ${BASIC_PACKAGE_LIMIT} highlights!`,
        duration: 5000,
      })
      return
    }

    const { data: user } = await supabase.auth.getUser()
    if (!user.user) return

    const { error } = await supabase.from("highlights").insert({
      ...newHighlight,
      user_id: user.user.id,
      position: highlights.length,
    })

    if (error) {
      toast({
        title: "Error",
        description: "Failed to add highlight",
        variant: "destructive",
      })
      return
    }

    setIsAddingHighlight(false)
    setNewHighlight({ title: "", description: "", video_url: "" })
    fetchHighlights()
    toast({
      title: "Success",
      description: "Highlight added successfully",
    })
  }

  const handleDeleteHighlight = async (id: string) => {
    const { error } = await supabase.from("highlights").delete().eq("id", id)

    if (error) {
      toast({
        title: "Error",
        description: "Failed to delete highlight",
        variant: "destructive",
      })
      return
    }

    fetchHighlights()
    toast({
      title: "Success",
      description: "Highlight deleted successfully",
    })
  }

  const handleShare = async (highlightId: string) => {
    // This will be implemented in the next iteration
    toast({
      title: "Coming Soon",
      description: "Sharing functionality will be available soon!",
    })
  }

  const handleFileUpload = (files: FileList) => {
    // In a real implementation, this would upload to storage
    console.log("File selected:", files[0].name)
    setNewHighlight(prev => ({
      ...prev,
      video_url: files[0].name // This is just for demo
    }))
  }

  return (
    <Card className="dashboard-card">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold">Manage Highlights</h2>
        <Dialog open={isAddingHighlight} onOpenChange={setIsAddingHighlight}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="h-4 w-4 mr-2" />
              Add Highlight
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Highlight</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <Input
                  placeholder="Title"
                  value={newHighlight.title}
                  onChange={(e) =>
                    setNewHighlight({ ...newHighlight, title: e.target.value })
                  }
                />
              </div>
              <div>
                <Textarea
                  placeholder="Description"
                  value={newHighlight.description}
                  onChange={(e) =>
                    setNewHighlight({
                      ...newHighlight,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <FileUpload onUpload={handleFileUpload} />
              </div>
              <Button onClick={handleAddHighlight} className="w-full">
                Add Highlight
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>

      {highlights.length >= MAX_FREE_HIGHLIGHTS && (
        <div className="mb-6 p-4 bg-muted rounded-lg border border-primary/20">
          <div className="flex items-center gap-2 text-primary mb-2">
            <Lock className="h-4 w-4" />
            <span className="font-medium">Upgrade to Add More Highlights</span>
          </div>
          <p className="text-sm text-muted-foreground mb-4">
            Upgrade to our Basic package for ${BASIC_PACKAGE_PRICE}/month to showcase
            up to {BASIC_PACKAGE_LIMIT} highlights!
          </p>
          <Button variant="default" className="w-full sm:w-auto">
            Upgrade Now
          </Button>
        </div>
      )}

      <div className="space-y-4">
        {highlights.map((highlight, index) => (
          <div
            key={highlight.id}
            className="flex items-center justify-between p-4 border rounded-lg hover:bg-accent/5"
          >
            <div className="flex-1">
              <h3 className="font-medium">{highlight.title}</h3>
              <p className="text-sm text-muted-foreground">
                {highlight.description}
              </p>
            </div>
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleShare(highlight.id)}
              >
                <Share2 className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleDeleteHighlight(highlight.id)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}

        {highlights.length === 0 && (
          <div className="text-center py-8 text-muted-foreground">
            No highlights added yet. Add your first highlight to showcase your
            skills!
          </div>
        )}
      </div>
    </Card>
  )
}