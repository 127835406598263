import { CheckCircle2 } from "lucide-react"

const athleteFeatures = [
  {
    title: "Build Your Profile",
    points: [
      "Share your stats, achievements, and skills",
      "Upload unlimited videos to your profile library",
      "Select key highlights for maximum visibility",
    ],
  },
  {
    title: "Engage Through Reels",
    points: [
      "Post short video highlights in reels",
      "Create reels from your showcase videos",
      "Unlock unlimited posting with paid tiers",
    ],
  },
  {
    title: "Gain Visibility",
    points: [
      "Appear in scout searches worldwide",
      "Get priority placement with paid tiers",
      "Connect with clubs and coaches directly",
    ],
  },
]

const athleteBenefits = [
  {
    title: "No Barriers",
    description: "Whether you're just starting out or ready to take the next step, Draft'd helps you get noticed.",
  },
  {
    title: "Global Exposure",
    description: "Scouts and clubs from all over the world are using Draft'd to find new talent.",
  },
  {
    title: "Career Support",
    description: "Draft'd doesn't just help you stand out—it helps you grow.",
  },
]

export const AthleteFeatures = () => {
  return (
    <section className="py-24 px-6 bg-background/50">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-semibold mb-8 text-center">For Athletes: Showcase Your Talent</h2>
        
        <div className="mb-16">
          <h3 className="text-2xl font-semibold mb-6">What Can You Do on Draft'd?</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {athleteFeatures.map((feature, index) => (
              <div key={index} className="dashboard-card">
                <div className="text-2xl font-bold text-primary mb-4">{index + 1}</div>
                <h4 className="text-xl font-semibold mb-4">{feature.title}</h4>
                <ul className="space-y-3 text-left">
                  {feature.points.map((point, pIndex) => (
                    <li key={pIndex} className="flex gap-2">
                      <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-1" />
                      <span className="text-muted-foreground">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center max-w-3xl mx-auto">
          <h3 className="text-2xl font-semibold mb-6">Why Athletes Love Draft'd</h3>
          <div className="grid md:grid-cols-3 gap-8">
            {athleteBenefits.map((benefit, index) => (
              <div key={index} className="dashboard-card">
                <h4 className="text-xl font-semibold mb-3">{benefit.title}</h4>
                <p className="text-muted-foreground">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}