import { useNavigate } from "react-router-dom"

export const HeroSection = () => {
  const navigate = useNavigate()
  
  return (
    <section className="relative pt-64 px-6 bg-background">
      <div className="absolute top-8 left-8">
        <img 
          src="/lovable-uploads/b173936d-d97b-479b-9344-af4583122c52.png" 
          alt="Draft'd Logo" 
          className="h-12 w-auto cursor-pointer hover:opacity-80 transition-opacity"
          onClick={() => navigate("/")}
          role="button"
          tabIndex={0}
        />
      </div>
      <div className="max-w-4xl mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-6">
          Your Talent. Your Platform.
        </h1>
        <p className="text-xl text-muted-foreground mb-8">
          Draft'd connects athletes, scouts, and clubs in a dynamic, user-friendly platform designed to give you the visibility and tools you need to succeed. Whether you're an aspiring athlete or a scout searching for the perfect player, Draft'd simplifies the journey for everyone.
        </p>
      </div>
    </section>
  )
}