import { Card } from "@/components/ui/card"
import { FileText } from "lucide-react"

interface PersonalStatementSectionProps {
  playerData: any;
}

export function PersonalStatementSection({ playerData }: PersonalStatementSectionProps) {
  return (
    <Card className="dashboard-card">
      <div className="flex items-center gap-2 mb-4">
        <FileText className="h-5 w-5 text-muted-foreground" />
        <h2 className="text-lg font-semibold text-foreground">About Me</h2>
      </div>
      <p className="text-muted-foreground leading-relaxed">
        {playerData?.bio || "No bio provided yet"}
      </p>
    </Card>
  )
}