import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"
import { useState } from "react"

export function PersonalStatementForm({ form }: { form: any }) {
  const [wordCount, setWordCount] = useState(0)
  const MAX_WORDS = 150

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>, onChange: (value: string) => void) => {
    const text = e.target.value
    const words = text.trim().split(/\s+/).filter(word => word.length > 0)
    
    if (words.length <= MAX_WORDS) {
      onChange(text)
      setWordCount(words.length)
    }
  }

  return (
    <div className="space-y-6">
      <FormField
        control={form.control}
        name="bio"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Personal Statement</FormLabel>
            <FormDescription>
              Briefly describe your journey and what makes you unique as a player. Keep it concise - your skills and highlight videos will do most of the talking.
            </FormDescription>
            <FormControl>
              <Textarea 
                placeholder="Tell us about your journey..."
                className="min-h-[150px]"
                {...field}
                onChange={(e) => handleTextChange(e, field.onChange)}
              />
            </FormControl>
            <div className="text-sm text-muted-foreground">
              {wordCount}/{MAX_WORDS} words
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}