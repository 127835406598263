import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { useToast } from "@/hooks/use-toast"
import { Avatar } from "@/components/ui/avatar"
import { ThumbsUp, MessageCircle, Share2, Send, Image, Link2, Smile } from "lucide-react"
import { useState } from "react"
import { Separator } from "@/components/ui/separator"

export default function Home() {
  const [newPost, setNewPost] = useState("")
  const { toast } = useToast()

  // Mock data for demonstration
  const posts = [
    {
      id: 1,
      author: {
        name: "Sarah Johnson",
        role: "Forward at Manchester City",
        avatar: "/placeholder.svg"
      },
      content: "Just finished an amazing training session with the team! Working on new techniques to improve our attacking plays. #Football #Training",
      timestamp: "2h ago",
      likes: 24,
      comments: 5
    },
    {
      id: 2,
      author: {
        name: "David Martinez",
        role: "Scout at Real Madrid",
        avatar: "/placeholder.svg"
      },
      content: "Looking for talented young forwards in the London area. DM me if you're interested in a trial! #Recruitment #Football",
      timestamp: "5h ago",
      likes: 56,
      comments: 12
    }
  ]

  const handlePost = () => {
    if (!newPost.trim()) {
      toast({
        title: "Error",
        description: "Please write something before posting",
        variant: "destructive"
      })
      return
    }
    
    toast({
      title: "Success",
      description: "Post created successfully!"
    })
    setNewPost("")
  }

  return (
    <div className="max-w-4xl mx-auto px-4 space-y-8 animate-fade-in">
      {/* Create Post Card */}
      <Card className="glass-card p-6">
        <div className="flex gap-4">
          <Avatar className="w-12 h-12 ring-2 ring-primary/20">
            <img src="/placeholder.svg" alt="Your profile" className="object-cover" />
          </Avatar>
          <div className="flex-1 space-y-4">
            <Textarea 
              placeholder="What's on your mind?"
              value={newPost}
              onChange={(e) => setNewPost(e.target.value)}
              className="form-input min-h-[120px] resize-none bg-background/50 backdrop-blur-sm"
            />
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <Button variant="ghost" size="sm" className="text-muted-foreground hover:text-primary">
                  <Image className="h-5 w-5 mr-1" />
                  Photo
                </Button>
                <Button variant="ghost" size="sm" className="text-muted-foreground hover:text-primary">
                  <Link2 className="h-5 w-5 mr-1" />
                  Link
                </Button>
                <Button variant="ghost" size="sm" className="text-muted-foreground hover:text-primary">
                  <Smile className="h-5 w-5 mr-1" />
                  Feeling
                </Button>
              </div>
              <Button 
                onClick={handlePost} 
                className="glass-button"
              >
                <Send className="h-4 w-4 mr-2" />
                Post
              </Button>
            </div>
          </div>
        </div>
      </Card>

      {/* Posts Feed */}
      <div className="space-y-6">
        {posts.map((post) => (
          <Card 
            key={post.id} 
            className="glass-card p-6 hover:shadow-lg transition-all duration-300"
          >
            <div className="flex items-start gap-4">
              <Avatar className="w-12 h-12 ring-2 ring-primary/20">
                <img src={post.author.avatar} alt={post.author.name} className="object-cover" />
              </Avatar>
              <div className="flex-1">
                <div className="flex flex-col">
                  <h3 className="font-semibold text-foreground">{post.author.name}</h3>
                  <p className="text-sm text-muted-foreground">{post.author.role}</p>
                  <span className="text-xs text-muted-foreground/60 mt-1">{post.timestamp}</span>
                </div>
                <p className="mt-4 text-foreground leading-relaxed">{post.content}</p>
                
                <Separator className="my-4" />
                
                {/* Post Actions */}
                <div className="flex gap-6">
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="flex-1 text-muted-foreground hover:text-primary hover:bg-primary/10"
                  >
                    <ThumbsUp className="h-4 w-4 mr-2" />
                    {post.likes}
                  </Button>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="flex-1 text-muted-foreground hover:text-primary hover:bg-primary/10"
                  >
                    <MessageCircle className="h-4 w-4 mr-2" />
                    {post.comments}
                  </Button>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="flex-1 text-muted-foreground hover:text-primary hover:bg-primary/10"
                  >
                    <Share2 className="h-4 w-4 mr-2" />
                    Share
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}