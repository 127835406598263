// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://hwtdnjqrvfbxybkhqikx.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imh3dGRuanFydmZieHlia2hxaWt4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzY4NjczODgsImV4cCI6MjA1MjQ0MzM4OH0.YBKMp-pYgYgPNmoBaH_rUT_lNFsWTRVKOzZurw1tmE0";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);