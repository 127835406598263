import { ArrowRight } from "lucide-react"
import { Button } from "./ui/button"
import { useNavigate } from "react-router-dom"

const Hero = () => {
  const navigate = useNavigate()

  const handleLearnMore = () => {
    console.log("Navigating to learn more page")
    navigate("/learn-more")
  }

  return (
    <section className="relative min-h-[85vh] flex items-center justify-center bg-gradient-to-b from-background via-background to-accent/5">
      <div className="absolute top-8 left-8">
        <img 
          src="/lovable-uploads/b173936d-d97b-479b-9344-af4583122c52.png" 
          alt="Draft'd Logo" 
          className="h-12 w-auto md:h-12 h-9 cursor-pointer hover:opacity-80 transition-opacity"
          onClick={() => navigate("/")}
          role="button"
          tabIndex={0}
        />
      </div>
      <div className="max-w-6xl mx-auto px-6 -mt-20">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/80 animate-fadeIn">
            Connect. Showcase. Succeed.
          </h1>
          <p className="text-xl text-muted-foreground mb-8 animate-fadeIn [animation-delay:200ms]">
            Draft'd connects athletes with scouts, clubs, and coaches, giving you the visibility you deserve. Whether you're chasing a professional career or showcasing your talent, Draft'd is your bridge to the next level.
          </p>
          <div className="flex gap-4 justify-center animate-fadeIn [animation-delay:400ms]">
            <Button 
              size="lg"
              className="glass-button inline-flex items-center gap-2 text-base"
              onClick={() => document.getElementById('waitlist')?.scrollIntoView({ behavior: 'smooth' })}
            >
              Join Waitlist
              <ArrowRight className="h-4 w-4" />
            </Button>
            <Button
              size="lg"
              variant="outline"
              className="bg-background/50 hover:bg-background text-foreground border-border/50 backdrop-blur-sm text-base"
              onClick={handleLearnMore}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero