import { FileUploadGroup } from "@/components/squad/FileUploadGroup"
import { FormDescription, FormLabel } from "@/components/ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { InfoIcon } from "lucide-react"

const HIGHLIGHT_CATEGORIES = {
  "Goals": ["Long-range shots", "Headers", "Free kicks", "Penalties", "Tap-ins", "Volleys"],
  "Assists": ["Through balls", "Crosses", "Set-piece deliveries", "Flick-ons"],
  "Defensive": ["Tackles", "Interceptions", "Blocks", "Clearances", "Last-man challenges"],
  "Dribbling": ["1v1 beats", "Multiple players beaten", "Skill moves", "Ball control"],
  "Passing": ["Long-range passes", "Key passes", "One-touch sequences", "Switches of play"],
  "Aerial": ["Aerial duels", "Scoring headers", "Defensive headers"],
  "Set Pieces": ["Free-kick goals", "Penalties", "Corners"],
  "Pressing": ["High pressing", "Ball recovery", "Tracking back"],
  "Physical": ["Speed", "Strength", "Agility"],
  "Team Play": ["Vision", "Link-up play", "Off-ball runs"],
  "Goalkeeping": ["Shot-stopping", "Penalty saves", "Reflexes", "Distribution"],
  "Special": ["Match-winning moments", "Big game performances", "Leadership"]
}

interface HighlightVideo {
  id: number;
  category: string;
  subcategory: string;
  description: string;
}

export function MediaUploadsForm({ form }: { form: any }) {
  const [videos, setVideos] = useState<HighlightVideo[]>([])
  const [currentCategory, setCurrentCategory] = useState("")
  const [currentSubcategory, setCurrentSubcategory] = useState("")
  const [description, setDescription] = useState("")

  const handleAddVideo = () => {
    if (videos.length >= 5) {
      return
    }
    
    const newVideo: HighlightVideo = {
      id: videos.length + 1,
      category: currentCategory,
      subcategory: currentSubcategory,
      description
    }
    
    setVideos([...videos, newVideo])
    setCurrentCategory("")
    setCurrentSubcategory("")
    setDescription("")
  }

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div>
          <FormLabel>Highlight Videos ({videos.length}/5)</FormLabel>
          <FormDescription>
            Upload up to 5 highlight videos from different matches showcasing various aspects of your game
          </FormDescription>
        </div>

        <Alert>
          <InfoIcon className="h-4 w-4" />
          <AlertDescription>
            For the best impression, include clips from different matches showing various skills. Label each video clearly to help scouts understand what they're watching.
          </AlertDescription>
        </Alert>

        {videos.length < 5 && (
          <div className="space-y-4 border p-4 rounded-lg">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <FormLabel>Category</FormLabel>
                <Select value={currentCategory} onValueChange={setCurrentCategory}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select category" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(HIGHLIGHT_CATEGORIES).map((category) => (
                      <SelectItem key={category} value={category}>
                        {category}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <FormLabel>Specific Skill</FormLabel>
                <Select 
                  value={currentSubcategory} 
                  onValueChange={setCurrentSubcategory}
                  disabled={!currentCategory}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select skill" />
                  </SelectTrigger>
                  <SelectContent>
                    {currentCategory && HIGHLIGHT_CATEGORIES[currentCategory as keyof typeof HIGHLIGHT_CATEGORIES].map((skill) => (
                      <SelectItem key={skill} value={skill}>
                        {skill}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div>
              <FormLabel>Brief Description</FormLabel>
              <Input 
                placeholder="e.g., 'Free kick goal vs United, 85th minute winner'"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <FileUploadGroup
              form={form}
              name={`highlightVideo${videos.length + 1}`}
              label={`Highlight Video ${videos.length + 1}`}
            />

            <Button 
              type="button" 
              onClick={handleAddVideo}
              disabled={!currentCategory || !currentSubcategory || !description}
            >
              Add Video
            </Button>
          </div>
        )}

        {videos.map((video, index) => (
          <div key={video.id} className="border p-4 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <h4 className="font-medium">Highlight Video {index + 1}</h4>
                <p className="text-sm text-muted-foreground">
                  {video.category} - {video.subcategory}
                </p>
                <p className="text-sm">{video.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}