import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Edit, Heart, Target } from "lucide-react"
import { useToast } from "@/components/ui/use-toast"

interface InterestsGoalsSectionProps {
  playerData: {
    sportsInterests?: string;
    careerGoals?: string;
    preferredLeagues?: string;
  };
}

export function InterestsGoalsSection({ playerData }: InterestsGoalsSectionProps) {
  const { toast } = useToast()
  
  const handleEditClick = () => {
    toast({
      title: "Edit mode",
      description: "This feature is coming soon!"
    })
  }

  return (
    <Card className="dashboard-card">
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-lg font-semibold">Interests & Goals</h2>
        <Button variant="ghost" size="sm" onClick={handleEditClick}>
          <Edit className="h-4 w-4" />
        </Button>
      </div>
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Heart className="h-4 w-4 text-muted-foreground" />
          <div>
            <p className="text-sm text-muted-foreground">Sports Interests</p>
            <p className="font-medium">{playerData?.sportsInterests || "Not specified"}</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Target className="h-4 w-4 text-muted-foreground" />
          <div>
            <p className="text-sm text-muted-foreground">Career Goals</p>
            <p className="font-medium">{playerData?.careerGoals || "Not specified"}</p>
          </div>
        </div>
        {playerData?.preferredLeagues && (
          <div>
            <p className="text-sm text-muted-foreground">Preferred Leagues</p>
            <p className="font-medium">{playerData.preferredLeagues}</p>
          </div>
        )}
      </div>
    </Card>
  )
}