
export const discoveryMethods = [
  { id: "attending_matches", label: "Attending matches in person" },
  { id: "word_of_mouth", label: "Word of mouth/recommendations" },
  { id: "video_footage", label: "Video footage (YouTube, Hudl, etc.)" },
  { id: "social_media", label: "Social media (Instagram, Twitter, TikTok)" },
  { id: "direct_contact", label: "Direct contact from players or agents" },
]

export const scoutingChallenges = [
  { id: "finding_quality", label: "Finding high-quality players" },
  { id: "verifying_level", label: "Verifying a player's actual level and experience" },
  { id: "access_footage", label: "Getting access to enough footage or match data" },
  { id: "unqualified_players", label: "Too many unqualified players contacting me" },
  { id: "time_constraints", label: "Time constraints (balancing scouting with other tasks)" },
  { id: "travel_costs", label: "Travel costs & logistics" },
  { id: "communication", label: "Difficulty communicating with clubs & players" },
  { id: "competition", label: "Competition from other scouts/clubs" },
]

export const platformConcerns = [
  { id: "existing_methods", label: "I already have scouting methods that work well" },
  { id: "talent_quality", label: "Not seeing any high level talent" },
  { id: "footage_type", label: "I prefer full-match footage over highlight reels" },
  { id: "verification_needed", label: "I'd need a way to verify a player's actual level" },
  { id: "cost_concerns", label: "Cost—would need to see the value before considering a paid option" },
]

export const ageGroups = [
  { id: "pre_academy", label: "Pre-Academy: 6–11" },
  { id: "academy", label: "Academy: 12–16" },
  { id: "development", label: "Development Squads: 17–21" },
  { id: "senior", label: "Senior Teams: 22+" },
]

export const featuresList = [
  { id: "search_filtering", label: "Search & filtering (by position, nationality, physical attributes, etc.)" },
  { id: "verified_history", label: "Verified player history (confirming where they've played)" },
  { id: "highlight_reels", label: "Easy access to highlight reels" },
  { id: "messaging", label: "Ability to message players directly" },
  { id: "availability", label: "Player availability status (whether they're looking for a club)" },
]
