import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Edit, UserCheck } from "lucide-react"
import { useToast } from "@/components/ui/use-toast"

interface ReferencesSectionProps {
  playerData: {
    coachName?: string;
    coachContact?: string;
  };
}

export function ReferencesSection({ playerData }: ReferencesSectionProps) {
  const { toast } = useToast()
  
  const handleEditClick = () => {
    toast({
      title: "Edit mode",
      description: "This feature is coming soon!"
    })
  }

  return (
    <Card className="dashboard-card">
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center gap-2">
          <UserCheck className="h-5 w-5 text-muted-foreground" />
          <h2 className="text-lg font-semibold">References</h2>
        </div>
        <Button variant="ghost" size="sm" onClick={handleEditClick}>
          <Edit className="h-4 w-4" />
        </Button>
      </div>
      <div className="space-y-4">
        {playerData?.coachName && (
          <div>
            <p className="text-sm text-muted-foreground">Coach/Trainer Name</p>
            <p className="font-medium">{playerData.coachName}</p>
          </div>
        )}
        {playerData?.coachContact && (
          <div>
            <p className="text-sm text-muted-foreground">Contact Information</p>
            <p className="font-medium">{playerData.coachContact}</p>
          </div>
        )}
        {!playerData?.coachName && !playerData?.coachContact && (
          <p className="text-sm text-muted-foreground">No references provided</p>
        )}
      </div>
    </Card>
  )
}