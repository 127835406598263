import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Loader2 } from "lucide-react"
import { supabase } from "@/lib/supabase"
import { useToast } from "@/components/ui/use-toast"
import { ProfileHeader } from "@/components/profile/sections/ProfileHeader"
import { BasicInfoSection } from "@/components/profile/sections/BasicInfoSection"
import { PositionExperienceSection } from "@/components/profile/sections/PositionExperienceSection"
import { PhysicalAttributesSection } from "@/components/profile/sections/PhysicalAttributesSection"
import { PersonalStatementSection } from "@/components/profile/sections/PersonalStatementSection"
import { HighlightsSection } from "@/components/profile/sections/HighlightsSection"
import { AvailabilitySection } from "@/components/profile/sections/AvailabilitySection"
import { InterestsGoalsSection } from "@/components/profile/sections/InterestsGoalsSection"
import { ReferencesSection } from "@/components/profile/sections/ReferencesSection"

interface PlayerProfile {
  id: string;
  full_name: string;
  date_of_birth: string;
  gender: string;
  city: string;
  country: string;
  profile_picture_url?: string;
  position?: string;
  current_club?: string;
  previous_clubs?: string;
  height?: string;
  weight?: string;
  dominant_foot?: string;
  bio?: string;
  open_to_trials?: string;
  willing_to_relocate?: string;
  preferred_contact?: string;
  sports_interests?: string;
  career_goals?: string;
  coach_name?: string;
  coach_contact?: string;
  preferred_leagues?: string;
  email?: string;
  phone?: string;
}

export default function MyProfile() {
  const [playerData, setPlayerData] = useState<PlayerProfile | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { toast } = useToast()

  useEffect(() => {
    console.log("MyProfile: Initial render")
    
    const loadProfile = async () => {
      try {
        console.log("MyProfile: Loading profile data")
        const { data: { user }, error: authError } = await supabase.auth.getUser()

        if (authError || !user) {
          console.error("MyProfile: No authenticated user found:", authError)
          navigate("/login")
          return
        }

        console.log("MyProfile: Found authenticated user:", user.id)

        const { data: profile, error } = await supabase
          .from('player_profiles')
          .select('*')
          .eq('user_id', user.id)
          .single()

        if (error) {
          console.error("MyProfile: Error fetching profile:", error)
          toast({
            title: "Error",
            description: "Could not load your profile. Please try again.",
            variant: "destructive",
          })
          return
        }

        if (!profile) {
          console.log("MyProfile: No profile found, redirecting to create profile")
          navigate("/create-player-profile")
          return
        }

        console.log("MyProfile: Successfully loaded profile:", profile)
        setPlayerData(profile)
      } catch (error) {
        console.error("MyProfile: Error loading profile:", error)
        toast({
          title: "Error",
          description: "An unexpected error occurred. Please try again.",
          variant: "destructive",
        })
      } finally {
        setIsLoading(false)
      }
    }

    loadProfile()
  }, [navigate, toast])

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-background">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    )
  }

  if (!playerData) {
    return null
  }

  const handleEdit = () => {
    navigate("/create-player-profile")
  }

  const basicInfoData = {
    fullName: playerData.full_name,
    dateOfBirth: playerData.date_of_birth,
    gender: playerData.gender,
    city: playerData.city,
    country: playerData.country,
    email: playerData.email,
    phone: playerData.phone
  }

  const positionData = {
    position: playerData.position,
    currentClub: playerData.current_club,
    previousClubs: playerData.previous_clubs
  }

  const physicalData = {
    height: playerData.height,
    weight: playerData.weight,
    dominantFoot: playerData.dominant_foot
  }

  const interestsData = {
    sportsInterests: playerData.sports_interests,
    careerGoals: playerData.career_goals,
    preferredLeagues: playerData.preferred_leagues
  }

  const referencesData = {
    coachName: playerData.coach_name,
    coachContact: playerData.coach_contact
  }

  return (
    <div className="min-h-screen bg-background">
      <div className="max-w-7xl mx-auto px-6 space-y-8">
        <ProfileHeader playerData={playerData} onEdit={handleEdit} />
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Main Info */}
          <div className="lg:col-span-2 space-y-6">
            <PersonalStatementSection playerData={playerData} />
            <PositionExperienceSection playerData={positionData} />
            <HighlightsSection playerData={playerData} />
            <InterestsGoalsSection playerData={interestsData} />
          </div>
          
          {/* Right Column - Additional Info */}
          <div className="space-y-6">
            <BasicInfoSection playerData={basicInfoData} />
            <PhysicalAttributesSection playerData={physicalData} />
            <AvailabilitySection playerData={playerData} />
            <ReferencesSection playerData={referencesData} />
          </div>
        </div>
      </div>
    </div>
  )
}
