import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { useToast } from "@/components/ui/use-toast"
import { useNavigate } from "react-router-dom"
import { supabase } from "@/lib/supabase"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { AlertCircle, Gift, UserPlus, Users } from "lucide-react"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { useState } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

const signUpSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  phone: z.string().min(10, "Phone number must be at least 10 digits"),
  userType: z.enum(["player", "scout"], {
    required_error: "Please select whether you're a player or scout",
  }),
  password: z.string()
    .min(8, "Password must be at least 8 characters")
    .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
    .regex(/[a-z]/, "Password must contain at least one lowercase letter")
    .regex(/[0-9]/, "Password must contain at least one number"),
  referralCode: z.string().optional(),
})

export type SignUpFormData = z.infer<typeof signUpSchema>

export function SignUpForm() {
  const { toast } = useToast()
  const navigate = useNavigate()
  const [showReferralMessage, setShowReferralMessage] = useState(false)
  const [referralCode, setReferralCode] = useState("")
  
  const form = useForm<SignUpFormData>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: "",
      phone: "",
      userType: undefined,
      password: "",
      referralCode: "",
    },
  })

  console.log("Form state:", {
    values: form.getValues(),
    errors: form.formState.errors,
    isSubmitting: form.formState.isSubmitting,
    isDirty: form.formState.isDirty,
    isValid: form.formState.isValid
  })

  const onSubmit = async (values: SignUpFormData) => {
    console.log("Starting sign up submission with values:", values)

    try {
      // Verify referral code first if provided
      if (values.referralCode) {
        console.log("Verifying referral code:", values.referralCode)
        const { data: referralData, error: referralError } = await supabase
          .from('referral_codes')
          .select('code, entries')
          .eq('code', values.referralCode.toUpperCase())
          .maybeSingle()

        if (referralError || !referralData) {
          console.error("Invalid referral code:", referralError)
          toast({
            title: "Invalid Referral Code",
            description: "The referral code you entered is not valid.",
            variant: "destructive",
          })
          return
        }

        console.log("Valid referral code found:", referralData)
      }

      // Create the user account
      console.log("Calling Supabase auth.signUp")
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
        options: {
          data: {
            phone: values.phone,
            userType: values.userType,
          }
        }
      })

      if (authError) {
        console.error("Signup auth error:", authError)
        toast({
          title: "Error",
          description: authError.message,
          variant: "destructive",
        })
        return
      }

      console.log("Auth signup successful:", authData)

      // Wait a moment for the user creation and trigger to complete
      await new Promise(resolve => setTimeout(resolve, 1000))

      // Create interest registration after user is created
      const { error: registrationError } = await supabase
        .from('interest_registrations')
        .insert([
          {
            email: values.email,
            user_type: values.userType,
            referral_code: values.referralCode ? values.referralCode.toUpperCase() : null
          }
        ])

      if (registrationError) {
        console.error("Error creating interest registration:", registrationError)
        toast({
          title: "Warning",
          description: "Account created but there was an issue registering your interest.",
          variant: "default",
        })
      }

      // Fetch the user's referral code
      if (authData.user) {
        const { data: referralData, error: referralError } = await supabase
          .from('referral_codes')
          .select('code')
          .eq('user_id', authData.user.id)
          .single()

        if (referralError) {
          console.error("Error fetching referral code:", referralError)
        } else if (referralData) {
          setReferralCode(referralData.code)
          setShowReferralMessage(true)
        }
      }

      console.log("Storing user data in localStorage")
      localStorage.setItem('userEmail', values.email)
      localStorage.setItem('userPhone', values.phone)
      localStorage.setItem('userType', values.userType)

      toast({
        title: "Account Created",
        description: "Welcome to Draft'd!",
      })

      if (!showReferralMessage) {
        if (values.userType === "player") {
          console.log("Redirecting to create-player-profile")
          navigate("/create-player-profile")
        } else {
          console.log("Redirecting to dashboard")
          navigate("/dashboard")
        }
      }
    } catch (error) {
      console.error("Unexpected signup error:", error)
      toast({
        title: "Error",
        description: "There was a problem creating your account. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleContinue = () => {
    const userType = form.getValues('userType')
    if (userType === "player") {
      navigate("/create-player-profile")
    } else {
      navigate("/dashboard")
    }
  }

  const hasErrors = Object.keys(form.formState.errors).length > 0

  if (showReferralMessage) {
    return (
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            <Gift className="h-8 w-8 mx-auto mb-2 text-primary" />
            Enter Our Early Sign-Up Raffle!
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="text-center space-y-4">
            <p className="text-muted-foreground">
              Sign up for Draft'd and refer 3 friends using your unique referral code to win exclusive access to live scouting trials with top-tier scouts.
            </p>
            
            <div className="space-y-4">
              <h3 className="font-semibold">How to Enter:</h3>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <UserPlus className="h-5 w-5 text-primary" />
                  <p>1. Sign up for Draft'd ✓</p>
                </div>
                <div className="flex items-center gap-2">
                  <Users className="h-5 w-5 text-primary" />
                  <p>2. Share your unique referral code with 3 friends</p>
                </div>
                <div className="flex items-center gap-2">
                  <Gift className="h-5 w-5 text-primary" />
                  <p>3. Be entered into the raffle!</p>
                </div>
              </div>
            </div>

            <div className="bg-accent/30 p-4 rounded-lg">
              <p className="font-semibold mb-2">Your Unique Referral Code:</p>
              <p className="text-2xl font-bold text-primary">{referralCode}</p>
            </div>

            <div className="space-y-2 text-sm">
              <p className="font-semibold">The Prize:</p>
              <ul className="list-disc list-inside text-muted-foreground">
                <li>Exclusive invites to live scouting trials</li>
                <li>A chance to be seen by scouts from top-tier teams</li>
              </ul>
            </div>

            <div className="space-y-2 text-sm">
              <p className="font-semibold">Increase Your Chances:</p>
              <p className="text-muted-foreground">Every 3 friends you refer = 1 extra entry!</p>
            </div>

            <p className="text-sm text-muted-foreground italic">
              Hurry—only the first 500 athletes who sign up and refer will be entered!
            </p>
          </div>

          <Button 
            onClick={handleContinue}
            className="w-full bg-[#2E8DA7] hover:bg-[#2E8DA7]/90"
          >
            Continue to Profile
          </Button>
        </CardContent>
      </Card>
    )
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {hasErrors && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              Please fill in all required fields correctly before continuing
            </AlertDescription>
          </Alert>
        )}

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input 
                  type="email" 
                  placeholder="Enter your email" 
                  {...field} 
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone Number</FormLabel>
              <FormControl>
                <Input 
                  type="tel" 
                  placeholder="Enter your phone number" 
                  {...field} 
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="userType"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>I am a:</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="player" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Player
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="scout" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Scout/Club Representative
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input 
                  type="password" 
                  placeholder="Create a password" 
                  {...field} 
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="referralCode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Referral Code (Optional)</FormLabel>
              <FormControl>
                <Input 
                  type="text" 
                  placeholder="Enter referral code" 
                  {...field} 
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button 
          type="submit" 
          className="w-full bg-[#2E8DA7] hover:bg-[#2E8DA7]/90"
        >
          {form.formState.isSubmitting ? "Creating Account..." : "Create Account"}
        </Button>
      </form>
    </Form>
  )
}
