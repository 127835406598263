import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { Button } from "@/components/ui/button"
import { useToast } from "@/components/ui/use-toast"
import { Form } from "@/components/ui/form"
import { BasicInfoForm } from "./steps/BasicInfoForm"
import { ProfilePictureForm } from "./steps/ProfilePictureForm"
import { PositionForm } from "./steps/PositionForm"
import { PhysicalForm } from "./steps/PhysicalForm"
import { PersonalStatementForm } from "./steps/PersonalStatementForm"
import { MediaUploadsForm } from "./steps/MediaUploadsForm"
import { ContactForm } from "./steps/ContactForm"
import { AvailabilityForm } from "./steps/AvailabilityForm"
import { InterestsForm } from "./steps/InterestsForm"
import { ReferencesForm } from "./steps/ReferencesForm"
import { Progress } from "@/components/ui/progress"
import { AlertCircle } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { supabase } from "@/lib/supabase"

const formSchema = z.object({
  fullName: z.string().min(2, "Name must be at least 2 characters"),
  dateOfBirth: z.string().min(1, "Date of birth is required"),
  gender: z.string().min(1, "Gender is required"),
  city: z.string().min(1, "City is required"),
  country: z.string().min(1, "Country is required"),
  profilePicture: z.instanceof(File).optional(),
  position: z.string().optional(),
  currentClub: z.string().optional(),
  previousClubs: z.string().optional(),
  height: z.string().optional(),
  weight: z.string().optional(),
  dominantFoot: z.string().optional(),
  bio: z.string().optional(),
  openToTrials: z.string().optional(),
  willingToRelocate: z.string().optional(),
  preferredContact: z.string().optional(),
  sportsInterests: z.string().optional(),
  careerGoals: z.string().optional(),
  coachName: z.string().optional(),
  coachContact: z.string().optional(),
  preferredLeagues: z.string().optional(),
})

export type PlayerProfileFormData = z.infer<typeof formSchema>

const steps = [
  { id: "basic", label: "Basic Info", title: "Basic Information", required: true },
  { id: "profile-picture", label: "Profile Picture", title: "Upload Profile Picture", required: true },
  { id: "position", label: "Position & Experience", title: "Position & Experience Details", required: false },
  { id: "physical", label: "Physical Attributes", title: "Physical Attributes", required: false },
  { id: "statement", label: "Personal Statement", title: "Personal Statement", required: false },
  { id: "media", label: "Media", title: "Media Uploads", required: false },
  { id: "contact", label: "Contact", title: "Contact Preferences", required: false },
  { id: "availability", label: "Availability", title: "Availability", required: false },
  { id: "interests", label: "Interests & Goals", title: "Interests & Career Goals", required: false },
  { id: "references", label: "References", title: "References", required: false }
]

export function PlayerProfileForm() {
  const [currentStep, setCurrentStep] = useState(0)
  const [showWarning, setShowWarning] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { toast } = useToast()
  const navigate = useNavigate()

  console.log("PlayerProfileForm rendered, currentStep:", currentStep)

  const form = useForm<PlayerProfileFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      dateOfBirth: "",
      gender: "",
      city: "",
      country: "",
      profilePicture: undefined,
      position: "",
      currentClub: "",
      previousClubs: "",
      height: "",
      weight: "",
      dominantFoot: "",
      bio: "",
      openToTrials: "",
      willingToRelocate: "",
      preferredContact: "",
      sportsInterests: "",
      careerGoals: "",
      coachName: "",
      coachContact: "",
      preferredLeagues: "",
    },
  })

  // Calculate progress based on current step
  const progress = ((currentStep + 1) / steps.length) * 100

  const handleNext = async () => {
    if (currentStep === 0) {
      const isValid = await form.trigger(['fullName', 'dateOfBirth', 'gender', 'city', 'country'])
      if (!isValid) {
        toast({
          title: "Required Information Missing",
          description: "Please fill in all required fields to continue.",
          variant: "destructive"
        })
        return
      }
    }

    if (currentStep === 1 && !form.getValues('profilePicture')) {
      toast({
        title: "Profile Picture Required",
        description: "Please upload a profile picture to continue.",
        variant: "destructive"
      })
      return
    }

    if (currentStep < steps.length - 1) {
      const currentStepData = form.getValues()
      const hasData = Object.entries(currentStepData).some(([key, value]) => {
        const fieldBelongsToCurrentStep = getFieldsForStep(currentStep).includes(key)
        return fieldBelongsToCurrentStep && value !== undefined && value !== ""
      })
      
      if (!hasData && !steps[currentStep].required && !showWarning) {
        setShowWarning(true)
        return
      }
      setCurrentStep(prev => prev + 1)
      setShowWarning(false)
    } else {
      setIsSubmitting(true)
      try {
        const formData = form.getValues()
        console.log("Submitting player profile:", formData)
        
        const { data: { user } } = await supabase.auth.getUser()
        
        if (!user) {
          throw new Error("No authenticated user found")
        }

        console.log("Creating profile for user:", user.id)

        // Upload profile picture if exists
        let profilePictureUrl = null
        if (formData.profilePicture) {
          const file = formData.profilePicture
          const fileExt = file.name.split('.').pop()
          const fileName = `${user.id}-profile-picture.${fileExt}`
          
          const { data: uploadData, error: uploadError } = await supabase.storage
            .from('profile-pictures')
            .upload(fileName, file, {
              cacheControl: '3600',
              upsert: true
            })

          if (uploadError) {
            console.error("Error uploading profile picture:", uploadError)
            throw uploadError
          }

          console.log("Profile picture uploaded successfully:", uploadData)
          
          // Get the public URL for the uploaded file
          const { data: { publicUrl } } = supabase.storage
            .from('profile-pictures')
            .getPublicUrl(fileName)
            
          profilePictureUrl = publicUrl
        }

        // Save profile data to Supabase
        const { data: profileData, error: profileError } = await supabase
          .from('player_profiles')
          .upsert({
            user_id: user.id,
            full_name: formData.fullName,
            date_of_birth: formData.dateOfBirth,
            gender: formData.gender,
            city: formData.city,
            country: formData.country,
            profile_picture_url: profilePictureUrl,
            position: formData.position,
            current_club: formData.currentClub,
            previous_clubs: formData.previousClubs,
            height: formData.height,
            weight: formData.weight,
            dominant_foot: formData.dominantFoot,
            bio: formData.bio,
            open_to_trials: formData.openToTrials,
            willing_to_relocate: formData.willingToRelocate,
            preferred_contact: formData.preferredContact,
            sports_interests: formData.sportsInterests,
            career_goals: formData.careerGoals,
            coach_name: formData.coachName,
            coach_contact: formData.coachContact,
            preferred_leagues: formData.preferredLeagues,
            updated_at: new Date().toISOString()
          })
          .select()

        if (profileError) {
          console.error("Error saving profile data:", profileError)
          throw profileError
        }

        console.log("Profile saved successfully:", profileData)
        
        toast({
          title: "Profile Created!",
          description: "Your player profile has been created successfully."
        })
        
        navigate("/my-profile")
      } catch (error) {
        console.error("Error saving profile:", error)
        toast({
          title: "Error",
          description: "There was a problem saving your profile. Please try again.",
          variant: "destructive"
        })
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1)
      setShowWarning(false)
    }
  }

  const getFieldsForStep = (step: number): string[] => {
    switch (step) {
      case 0:
        return ['fullName', 'dateOfBirth', 'gender', 'city', 'country']
      case 1:
        return ['profilePicture']
      case 2:
        return ['position', 'currentClub', 'previousClubs']
      case 3:
        return ['height', 'weight', 'dominantFoot']
      case 4:
        return ['bio']
      case 5:
        return []
      case 6:
        return ['preferredContact']
      case 7:
        return ['openToTrials', 'willingToRelocate']
      case 8:
        return ['sportsInterests', 'careerGoals', 'preferredLeagues']
      case 9:
        return ['coachName', 'coachContact']
      default:
        return []
    }
  }

  const renderStepContent = () => {
    const currentStepData = steps[currentStep]
    return (
      <div className="space-y-6">
        <h1 className="text-3xl font-bold tracking-tight">{currentStepData.title}</h1>
        {(() => {
          switch (currentStep) {
            case 0:
              return <BasicInfoForm form={form} />
            case 1:
              return <ProfilePictureForm form={form} />
            case 2:
              return <PositionForm form={form} />
            case 3:
              return <PhysicalForm form={form} />
            case 4:
              return <PersonalStatementForm form={form} />
            case 5:
              return <MediaUploadsForm form={form} />
            case 6:
              return <ContactForm form={form} />
            case 7:
              return <AvailabilityForm form={form} />
            case 8:
              return <InterestsForm form={form} />
            case 9:
              return <ReferencesForm form={form} />
            default:
              return null
          }
        })()}
      </div>
    )
  }

  return (
    <Form {...form}>
      <div className="max-w-3xl mx-auto space-y-8 p-6">
        {showWarning && !steps[currentStep].required && (
          <Alert variant="default" className="border-yellow-500 bg-yellow-50 dark:bg-yellow-900/10">
            <AlertCircle className="h-4 w-4 text-yellow-600 dark:text-yellow-500" />
            <AlertTitle>Important Information Missing</AlertTitle>
            <AlertDescription>
              Skipping this section may reduce your visibility to scouts and clubs. Complete information helps them make better decisions about potential opportunities.
            </AlertDescription>
            <div className="mt-4 space-x-4">
              <Button variant="outline" onClick={() => setShowWarning(false)}>
                Go Back and Complete
              </Button>
              <Button variant="ghost" onClick={() => {
                setCurrentStep(prev => prev + 1)
                setShowWarning(false)
              }}>
                Skip Anyway
              </Button>
            </div>
          </Alert>
        )}

        <Progress value={progress} className="h-2" />

        <form onSubmit={(e) => {
          e.preventDefault()
          handleNext()
        }} className="space-y-4">
          {renderStepContent()}
          <div className="flex justify-between pt-6">
            <Button
              type="button"
              variant="outline"
              onClick={handleBack}
              disabled={currentStep === 0}
            >
              Back
            </Button>
            <Button 
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : currentStep === steps.length - 1 ? "Complete Profile" : "Continue"}
            </Button>
          </div>
        </form>
      </div>
    </Form>
  )
}
