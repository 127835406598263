import { FileUpload } from "@/components/FileUpload"
import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { useToast } from "@/components/ui/use-toast"

interface FileUploadGroupProps {
  form: any
  name: string
  label: string
}

export function FileUploadGroup({ form, name, label }: FileUploadGroupProps) {
  const { toast } = useToast()

  const handleUpload = (files: FileList) => {
    console.log(`Uploading ${files.length} files for ${name}`)
    toast({
      title: "Files Selected",
      description: `${files.length} files selected for ${label.toLowerCase()}`
    })
    // In a real app, you would handle the file upload here
  }

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <FileUpload onUpload={handleUpload} />
          </FormControl>
        </FormItem>
      )}
    />
  )
}