
import * as z from "zod"

export const formSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  scouting_level: z.enum(["grassroots", "semi_pro", "professional", "international"], {
    required_error: "Please select your scouting level"
  }),
  discovery_methods: z.array(z.string()).min(1, "Please select at least one discovery method"),
  players_scouted_range: z.enum(["1-10", "11-30", "31-50", "50+"], {
    required_error: "Please select the number of players you scout"
  }),
  scouting_challenges: z.array(z.string()).min(1, "Please select at least one challenge").max(3, "Please select up to 3 challenges"),
  evaluation_method: z.enum(["live_matches", "highlight_reels", "full_match_footage", "speaking_with_coaches"], {
    required_error: "Please select your primary evaluation method"
  }),
  difficulty_finding_players: z.number({
    required_error: "Please rate the difficulty of finding players"
  }).min(1).max(10),
  platform_interest: z.enum(["yes_active", "maybe_unsure", "not_useful"], {
    required_error: "Please indicate your interest in the platform"
  }),
  platform_concerns: z.array(z.string()).min(1, "Please select at least one concern"),
  other_platform_concern: z.string().optional(),
  payment_willingness: z.enum(["yes_would_pay", "maybe_depends", "no_would_not_pay"], {
    required_error: "Please indicate your willingness to pay"
  }),
  age_groups: z.array(z.string()).min(1, "Please select at least one age group"),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  location_city: z.string().optional(),
  location_country: z.string().optional(),
  feature_rankings: z.record(z.string(), z.number()).optional(),
})

export type FormValues = z.infer<typeof formSchema>
