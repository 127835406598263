import { Construction } from "lucide-react"

export default function UnderConstructionPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] space-y-4 animate-fade-in">
      <div className="glass-card p-8 text-center max-w-md mx-auto">
        <Construction className="w-16 h-16 text-primary mx-auto mb-4 animate-pulse" />
        <h2 className="text-2xl font-semibold text-foreground mb-2">Coming Soon</h2>
        <p className="text-muted-foreground">This page is under construction. Check back later!</p>
      </div>
    </div>
  )
}