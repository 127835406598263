import { Card } from "@/components/ui/card"
import { Scale, Ruler, Footprints } from "lucide-react"

interface PhysicalAttributesSectionProps {
  playerData: any;
}

export function PhysicalAttributesSection({ playerData }: PhysicalAttributesSectionProps) {
  return (
    <Card className="dashboard-card">
      <h2 className="text-lg font-semibold mb-4 text-foreground">Physical Attributes</h2>
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Ruler className="h-4 w-4 text-muted-foreground" />
          <div>
            <p className="text-sm text-muted-foreground">Height</p>
            <p className="font-medium text-foreground">{playerData?.height || "Not specified"} cm</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Scale className="h-4 w-4 text-muted-foreground" />
          <div>
            <p className="text-sm text-muted-foreground">Weight</p>
            <p className="font-medium text-foreground">{playerData?.weight || "Not specified"} kg</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Footprints className="h-4 w-4 text-muted-foreground" />
          <div>
            <p className="text-sm text-muted-foreground">Dominant Foot</p>
            <p className="font-medium text-foreground">{playerData?.dominantFoot || "Not specified"}</p>
          </div>
        </div>
      </div>
    </Card>
  )
}