import { Construction } from "lucide-react"
import { EnquiryForm } from "@/components/auth/EnquiryForm"
import Logo from "@/components/Logo"
import Features from "@/components/Features"
import Hero from "@/components/Hero"
import PricingTiers from "@/components/PricingTiers"
import { useAuth } from "@/hooks/useAuth"
import { useEffect } from "react"

const Index = () => {
  const { session } = useAuth()
  
  useEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling on unmount
      document.body.style.overflow = 'unset';
    };
  }, []);
  
  return (
    <>
      {/* Coming Soon Overlay */}
      <div 
        style={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 99999,
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(8px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          pointerEvents: 'auto',
        }}
      >
        <div className="max-w-3xl w-full mx-auto px-6">
          <div className="glass-card p-12 animate-fade-in">
            <Logo className="h-16 w-auto mx-auto mb-8" />
            <Construction className="w-20 h-20 text-primary mx-auto mb-8 animate-pulse" />
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Waitlist Opening Soon
            </h1>
            <p className="text-xl text-muted-foreground mb-8">
              We're putting the finishing touches on Draft'd, your ultimate platform connecting athletes and scouts. Join our waitlist soon to be among the first to experience it!
            </p>
          </div>
        </div>
      </div>

      {/* Base Content (hidden but functional) */}
      <div className="invisible relative min-h-screen bg-gradient-to-b from-background to-accent/10">
        <Hero />
        <section className="-mt-48 px-6 bg-background/50">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-semibold text-center mb-16">How Draft'd Works</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {howItWorks.map((step, index) => (
                <div key={index} className="dashboard-card text-center">
                  <div className="text-4xl font-bold text-primary mb-4">{index + 1}</div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-muted-foreground">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Features />
        <PricingTiers />
        <section id="waitlist" className="py-24 px-6 bg-background/50">
          <div className="max-w-3xl mx-auto text-center">
            <div className="dashboard-card p-12">
              <Construction className="w-16 h-16 text-primary mx-auto mb-6 animate-pulse" />
              <h2 className="text-3xl font-semibold mb-4">Waitlist Opening Soon</h2>
              <p className="text-xl text-muted-foreground">
                We're putting the finishing touches on something special. Check back soon to join our exclusive waitlist.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const howItWorks = [
  {
    title: "Create Your Profile",
    description: "Sign up for free, upload your stats, achievements, and video highlights, and let your talent shine.",
  },
  {
    title: "Get Discovered",
    description: "Scouts, clubs, and coaches explore profiles using powerful filters to find players who match their exact needs.",
  },
  {
    title: "Achieve Your Dreams",
    description: "Get contacted directly by scouts and take the next step in your athletic career.",
  },
]

export default Index