
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { Checkbox } from "@/components/ui/checkbox"
import { Slider } from "@/components/ui/slider"
import { UseFormReturn } from "react-hook-form"
import { FormValues } from "../schema"
import { scoutingChallenges } from "../constants"

interface ChallengesSectionProps {
  form: UseFormReturn<FormValues>
}

export const ChallengesSection = ({ form }: ChallengesSectionProps) => {
  const { register, setValue, getValues, formState: { errors } } = form;
  
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">Pain Points & Challenges</h2>
      
      <div id="section-scouting-challenges" className="space-y-4">
        <Label>What are the biggest challenges you face in scouting players? (Select up to 3)</Label>
        <div className="grid gap-2">
          {scoutingChallenges.map((challenge) => (
            <div key={challenge.id} className="flex items-center space-x-2">
              <Checkbox
                id={`scouting-challenges-${challenge.id}`}
                checked={getValues("scouting_challenges").includes(challenge.id)}
                disabled={
                  !getValues("scouting_challenges").includes(challenge.id) &&
                  getValues("scouting_challenges").length >= 3
                }
                onCheckedChange={(checked) => {
                  const current = getValues("scouting_challenges")
                  if (checked && current.length < 3) {
                    setValue("scouting_challenges", [...current, challenge.id], { shouldValidate: true })
                  } else if (!checked) {
                    setValue(
                      "scouting_challenges",
                      current.filter((id) => id !== challenge.id),
                      { shouldValidate: true }
                    )
                  }
                }}
              />
              <Label htmlFor={`scouting-challenges-${challenge.id}`}>{challenge.label}</Label>
            </div>
          ))}
        </div>
        {errors.scouting_challenges && (
          <p className="text-sm text-red-500">{errors.scouting_challenges.message}</p>
        )}
      </div>
      
      <div id="section-evaluation-method" className="space-y-4">
        <Label>How do you typically evaluate a player's level before considering them further?</Label>
        <RadioGroup
          defaultValue={getValues("evaluation_method")}
          onValueChange={(value) => {
            setValue("evaluation_method", value as FormValues["evaluation_method"]);
          }}
          className="grid gap-4"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem 
              value="live_matches" 
              id="evaluation-live-matches"
              {...register("evaluation_method", { required: true })}
            />
            <Label htmlFor="evaluation-live-matches">Watching live matches</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem 
              value="highlight_reels" 
              id="evaluation-highlight-reels"
              {...register("evaluation_method", { required: true })}
            />
            <Label htmlFor="evaluation-highlight-reels">Analyzing highlight reels</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem 
              value="full_match_footage" 
              id="evaluation-full-match-footage"
              {...register("evaluation_method", { required: true })}
            />
            <Label htmlFor="evaluation-full-match-footage">Reviewing full-match footage</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem 
              value="speaking_with_coaches" 
              id="evaluation-speaking-with-coaches"
              {...register("evaluation_method", { required: true })}
            />
            <Label htmlFor="evaluation-speaking-with-coaches">Speaking with coaches/agents</Label>
          </div>
        </RadioGroup>
        {errors.evaluation_method && (
          <p className="text-sm text-red-500">{errors.evaluation_method.message}</p>
        )}
      </div>
      
      <div id="section-difficulty-finding-players" className="space-y-4">
        <Label>
          On a scale from 1-10, how difficult is it to find quality players who are actually available to sign?
        </Label>
        <div>
          <Slider
            defaultValue={[getValues("difficulty_finding_players")]}
            max={10}
            min={1}
            step={1}
            onValueChange={([value]) => setValue("difficulty_finding_players", value, { shouldValidate: true })}
          />
        </div>
        <div className="flex justify-between text-sm text-gray-500">
          <span>Very Easy (1)</span>
          <span>Very Difficult (10)</span>
        </div>
        {errors.difficulty_finding_players && (
          <p className="text-sm text-red-500">{errors.difficulty_finding_players.message}</p>
        )}
      </div>
    </div>
  )
}

