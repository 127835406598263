import { Check } from "lucide-react"
import { cn } from "@/lib/utils"

const tiers = [
  {
    name: "Free Plan (Basic)",
    price: "0",
    description: "Perfect for athletes starting their journey.",
    features: [
      "Create a profile with stats and achievements",
      "Upload up to 5 video highlights to your showcase",
      "Appear in scout searches with standard visibility",
      "Access reels (post up to 5 video highlights from your showcase)",
      "Scouts can message you first",
    ],
  },
  {
    name: "Enhanced Visibility",
    price: "8.99",
    description: "Everything in the Basic Plan, plus:",
    features: [
      "Enhanced visibility in search results",
      "Upload up to 10 video highlights to your showcase",
      "Post up to 10 highlights on reels for more exposure",
      "Gain insights on profile views and scout interactions",
      "Message scouts and clubs first (up to 3 messages/month, excluding top-tier clubs and scouts)",
    ],
    annual: {
      price: "62.93",
      savings: "26.95",
      discount: "30",
    },
  },
  {
    name: "Pro",
    price: "19.99",
    description: "Everything in the Enhanced Visibility Plan, plus:",
    isPopular: true,
    features: [
      "Priority placement in searches and reels (appear higher)",
      "Advanced analytics to track performance and engagement",
      "Message scouts and clubs first (up to 6 messages/month, excluding top-tier clubs and scouts)",
    ],
    annual: {
      price: "143.93",
      savings: "95.95",
      discount: "40",
    },
  },
  {
    name: "Elite",
    price: "39.99",
    description: "Everything in the Pro Plan, plus:",
    features: [
      "Guaranteed top placement in searches and reels",
      "Send up to 10 total messages per month:",
      "7 messages to any clubs or scouts (not to top-tier clubs or scouts)",
      "3 messages to top-tier clubs or scouts",
    ],
    annual: {
      price: "239.94",
      savings: "239.94",
      discount: "50",
    },
  },
]

const PricingTiers = () => {
  return (
    <section className="py-24 px-6 bg-background">
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h2 className="text-3xl font-semibold mb-4 text-foreground">
          Choose Your Path to Success
        </h2>
        <p className="text-muted-foreground max-w-2xl mx-auto">
          Select the plan that best matches your career goals
        </p>
      </div>
      <div className="max-w-7xl mx-auto grid md:grid-cols-4 gap-6">
        {tiers.map((tier) => (
          <div 
            key={tier.name} 
            className={cn(
              "dashboard-card min-w-[280px] relative",
              tier.isPopular && "border-primary shadow-lg shadow-primary/20 scale-105"
            )}
          >
            {tier.isPopular && (
              <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-primary text-primary-foreground px-3 py-1 rounded-full text-sm font-medium">
                Most Popular
              </div>
            )}
            <h3 className="text-xl font-semibold mb-2 text-foreground">{tier.name}</h3>
            <div className="flex items-baseline gap-1 mb-2">
              {tier.price === "0" ? (
                <span className="text-2xl font-bold text-foreground">Free</span>
              ) : (
                <>
                  <span className="text-2xl font-bold text-foreground">£{tier.price}</span>
                  <span className="text-muted-foreground">/month</span>
                </>
              )}
            </div>
            {tier.annual && (
              <div className="mb-4 p-3 bg-accent/10 rounded-lg">
                <p className="text-sm font-medium">Annual Option:</p>
                <p className="text-sm">£{tier.annual.price}/year</p>
                <p className="text-sm text-primary">
                  {tier.annual.discount}% off – save £{tier.annual.savings}
                </p>
              </div>
            )}
            <p className="text-muted-foreground mb-6">{tier.description}</p>
            <ul className="space-y-4 text-muted-foreground">
              {tier.features.map((feature) => (
                <li key={feature} className="flex items-start gap-3">
                  <Check className="h-5 w-5 text-primary shrink-0 mt-0.5" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  )
}

export default PricingTiers